import { IMsalContext, useMsal } from '@azure/msal-react';
import { Dropdown, Label, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, Stack, useTheme } from '@fluentui/react'
import { useEffect, useState } from 'react'
import { IUserSettings } from '../../../Models/IUserSettings';
import { ApiService } from '../../../Services/ApiService';
import './Settings.scss';
import { setLocalStorageItem, setUserSettings } from '../../../Services/Global';
import { generalStackStyles } from '../../../Models/StackStyling';

/**
 * Settings component for user settings page 
 *
 * @return {*} 
 */
export const Settings: React.FC<{}> = () => {

  const ctx: IMsalContext = useMsal();
  const apiService: ApiService = new ApiService(ctx);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [unitsSetting, setUnitsSetting] = useState('');
  const [currentSettings, setCurrentSettings] = useState<IUserSettings>();

  let theme = useTheme();

  const stackStyles = generalStackStyles(theme);

  useEffect(() => {
    async function getUserSettings() {
      const settingsResponse = await apiService.getAsync('settings');
      if (settingsResponse.status >= 400) {
        const err = `There was an issue getting User Settings. Status code: ${settingsResponse.status}`;
        setErrorMessage(err);
        throw new Error(err);
      }

      const settings: IUserSettings = await settingsResponse.json();
      setCurrentSettings(settings);
      setUnitsSetting(settings.preferredUnits);
      setIsLoading(false);
      setUserSettings(settings);
    }

    getUserSettings();
  }, [])

  
  /**
   * Handles saving user settings 
   *
   */
  const handleSaveChanges = async () => {
    setHasSaved(false);
    setIsBusy(true);
    const settings: IUserSettings = {
      preferredUnits: unitsSetting
    }
    setLocalStorageItem('userSettings', JSON.stringify(settings));
    const response = await apiService.postAsync(settings, 'settings');
    if (response.status >= 400) {
      let errorMessage = `An error occurred while saving settings. Status code: ${response.status}`;
      setIsLoading(false);
      setErrorMessage(errorMessage);
      setIsBusy(false);

      throw new Error(errorMessage);
    }

    setUserSettings(settings);
    setIsBusy(false);
    setHasSaved(true);
  }

  return (
    <>
      <Stack styles={stackStyles} style={{ width: 'auto' }}>
        <Stack horizontalAlign='center' horizontal >

          <Stack.Item>
            {
              isLoading === true ?
                <Spinner label='Loading user settings...' size={SpinnerSize.large} />
                :
                <>
                  <Stack.Item>
                    <Label>Display units</Label>
                    <Dropdown style={{ minWidth: 100 }} disabled={isBusy} placeholder={currentSettings?.preferredUnits || 'Not set'} options={[{ key: 'metric', text: 'Metric' }, { key: 'US', text: 'US' }]}
                      onChange={(item, e) => setUnitsSetting(e!.text.toLowerCase())} />
                    <label style={{ maxWidth: 300 }}>Sets default units to metric (celsius/kgs) or US (fahrenheit/lbs).</label>
                    {
                      unitsSetting === 'us' &&
                      <Stack.Item>
                        <MessageBar messageBarType={MessageBarType.error}>
                          <p>
                            US units are not currently supported. Please use metric units at this time.
                          </p>
                        </MessageBar>
                      </Stack.Item>
                    }
                  </Stack.Item>

                  <Stack.Item style={{ marginTop: 10 }}>
                    <PrimaryButton
                      disabled={isBusy || unitsSetting === 'us'}
                      onClick={handleSaveChanges}
                      iconProps={{ iconName: 'Save' }}
                      text='Save'
                    />
                  </Stack.Item>
                </>
            }
          </Stack.Item>
        </Stack>

        <Stack style={{ minHeight: 30, height: 30, marginTop: 10 }}>
          {
            isBusy &&
            <Stack.Item>
              <Spinner label='Saving...' />
            </Stack.Item>
          }
          {
            hasSaved &&
            <Stack.Item>
              <MessageBar messageBarType={MessageBarType.success}>
                Saved
              </MessageBar>
            </Stack.Item>
          }
        </Stack>
        {
          errorMessage === '' ?
            <></>
            :
            <p style={{ color: 'red' }}>
              {errorMessage}
            </p>
        }
      </Stack>

    </>
  )
}
