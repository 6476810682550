import { DefaultButton, Dropdown, IDropdownOption, Label, PrimaryButton, Spinner, SpinnerSize, Stack, useTheme } from "@fluentui/react"
import { useEffect, useMemo, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "../../../Services/Global";
import { generalStackStyles } from "../../../Models/StackStyling";
import { IMsalContext, useMsal } from "@azure/msal-react";
import { ApiService } from "../../../Services/ApiService";
import { getUnitConfigurationFromConfigurationKey } from "../../../Utils/UnitConfigurationHelper";

export const PositionSelection: React.FC<{ onChange: any, onIncompleteUnitConfiguration?: any }> = (props) => {
    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);
    let theme = useTheme();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingPositions, setIsLoadingPositions] = useState<boolean>(false);

    const [selectedIndustry, setSelectedIndustry] = useState<string>(getLocalStorageItem('position_industry') as string);
    const [selectedSubIndustry, setSelectedSubIndustry] = useState<string>(getLocalStorageItem('position_subindustry') as string);
    const [selectedApplication, setSelectedApplication] = useState<string>(getLocalStorageItem('position_application') as string);
    const [selectedSubApplication, setSelectedSubApplication] = useState<string>(getLocalStorageItem('position_subapplication') as string);
    const [selectedProcessStage, setSelectedProcessStage] = useState<string>((getLocalStorageItem('position_processstage') as string));
    const [selectedProductLine, setSelectedProductLine] = useState<string>(getLocalStorageItem('position_productline') as string);
    const [selectedAlfaLavalPosition, setSelectedAlfaLavalPosition] = useState<string>(getLocalStorageItem('position_alfalavalposition') as string);

    const [availableIndustries, setAvailableIndustries] = useState<IDropdownOption[]>([]);
    const [availableSubIndustries, setAvailableSubIndustries] = useState<IDropdownOption[]>([]);
    const [availableApplications, setAvailableApplications] = useState<IDropdownOption[]>([]);
    const [availableSubApplications, setAvailableSubApplications] = useState<IDropdownOption[]>([]);
    const [availableProductLines, setAvailableProductLines] = useState<IDropdownOption[]>([]);

    const [industries, setIndustries] = useState<IDropdownOption<any>[]>();
    const [subIndustries, setSubIndustries] = useState<IDropdownOption<any>[]>();
    const [applications, setApplications] = useState<IDropdownOption<any>[]>();
    const [subApplications, setSubApplications] = useState<IDropdownOption<any>[]>();
    const [productLines, setProductLines] = useState<IDropdownOption<any>[]>();
    const [processStages, setProcessStages] = useState<string[]>([]);
    const [alfaLavalPositions, setAlfaLavalPositions] = useState<string[]>([]);

    useEffect(() => {
        getUnitConfiguration();
    }, []);

    
    /**
     * Get unit configuration from the API and set the dropdown options accordingly
     *
     */
    async function getUnitConfiguration() {
        setIsLoading(true);
        const response = await apiService.getAsync(`settings/configurationnavigations`);
        if (!response.ok) {
            setIsLoading(false);
            throw new Error(response.statusText);
        }

        const unitConfigurations: any[] = await response.json();

        let inds: IDropdownOption[] = [];
        let subInds: IDropdownOption[] = [];
        let apps: IDropdownOption[] = [];
        let subApps: IDropdownOption[] = [];
        let lines: IDropdownOption[] = [];

        unitConfigurations.forEach((industry: any) => {
            inds?.push({
                key: industry.name,
                text: industry.name
            });
            industry.children?.forEach((subIndustry: any) => {
                subInds.find((s: any) => s.key === subIndustry.name) ||
                    subInds.push({
                        key: `${industry.name}-${subIndustry.name}`,
                        text: subIndustry.name
                    })
                subIndustry.children?.forEach((application: any) => {
                    apps.find((a: any) => a.key === application.name) ||
                        apps.push({
                            key: `${industry.name}-${subIndustry.name}-${application.name}`,
                            text: application.name
                        })
                    application.children?.forEach((subApplication: any) => {
                        subApps.find((s: any) => s.key === subApplication.name) ||
                            subApps.push({
                                key: `${industry.name}-${subIndustry.name}-${application.name}-${subApplication.name}`,
                                text: subApplication.name
                            })
                        subApplication.children?.forEach((prodLine: any) => {
                            lines.find((l: any) => l.key === prodLine.name) || lines.push({
                                key: `${industry.name}-${subIndustry.name}-${application.name}-${subApplication.name}-${prodLine.name}`,
                                text: prodLine.name
                            })
                        })
                    })
                })
            })
        });

        // store original options
        setAvailableIndustries(inds);
        setAvailableSubIndustries(subInds);
        setAvailableApplications(apps);
        setAvailableSubApplications(subApps);
        setAvailableProductLines(lines);

        setProductLines(lines);
        setIndustries(inds);
        setSubIndustries(subInds);
        setApplications(apps);
        setSubApplications(subApps);

        // set dropdown options according to selected values
        setSubIndustries(subInds?.filter(x => x.key.toString().includes(selectedIndustry)));
        setApplications(apps?.filter(x => x.key.toString().includes(selectedSubIndustry)));
        setSubApplications(subApps?.filter(x => x.key.toString().includes(selectedApplication)));
        setProductLines(lines?.filter(x => x.key.toString().includes(selectedSubApplication)));

        setIsLoading(false);
    }

    useEffect(() => {
        getProcessStages();
        if (selectedProcessStage !== '' && selectedProcessStage !== null && selectedProcessStage !== undefined) {
            getAlfaLavalPositionsAsync();

            props.onChange(selectedAlfaLavalPosition);
        }
    }, [selectedProductLine])

    useEffect(() => {
        getAlfaLavalPositionsAsync();
    }, [selectedProcessStage])

    
    /**
     * Get process stages from the API
     *
     * @return {*} 
     */
    async function getProcessStages() {
        if (selectedProductLine === '' || selectedProductLine === null || selectedProductLine === undefined) return;
        setIsLoadingPositions(true);

        const uc = getUnitConfigurationFromConfigurationKey(selectedProductLine);
        const response = await apiService.getAsync('settings/selections/processStages', uc);
        if (!response.ok) {
            setProcessStages([]);
            setIsLoadingPositions(false);
            throw new Error('Could not get process stages');
        }

        const result: string[] = await response.json();
        setIsLoadingPositions(false);
        setProcessStages(result);
    }

    
    /**
     * Get Alfa Laval positions from the API
     *
     * @return {*} 
     */
    async function getAlfaLavalPositionsAsync() {
        if (selectedProcessStage === undefined || selectedProcessStage === '' || selectedProcessStage === null || selectedProductLine === undefined || selectedProductLine === '' || selectedProductLine === null) return;
        setIsLoadingPositions(true);
        const uc = getUnitConfigurationFromConfigurationKey(selectedProcessStage);
        const response = await apiService.getAsync('settings/selections/positions', uc);
        if (!response.ok) {
            setAlfaLavalPositions([]);
            setIsLoadingPositions(false);
            throw new Error('Could not get positions');
        }

        const result: string[] = await response.json();
        setIsLoadingPositions(false);
        setAlfaLavalPositions(result);
    }

    
    /**
     * Handle change event for dropdowns
     *
     * @param {IDropdownOption<any>} [option]
     * @param {string} [name]
     * @return {*} 
     */
    const handleChange = (option?: IDropdownOption<any>, name?: string) => {
        if (option === undefined) return;

        switch (name) {
            case 'industry':
                setSelectedIndustry(option.key as string);
                setSubIndustries(availableSubIndustries?.filter(x => x.key.toString().includes(option.key as string)))
                setSelectedSubIndustry('');
                setSelectedApplication('');
                setSelectedSubApplication('');
                setSelectedProcessStage('');
                setSelectedAlfaLavalPosition('');
                setSelectedProductLine('');
                setAlfaLavalPositions([]);
                setProcessStages([])
                setLocalStorageItem('position_industry', option.key as string);
                props.onIncompleteUnitConfiguration();
                break;
            case 'subIndustry':
                setSelectedSubIndustry(option.key as string);
                setApplications(availableApplications?.filter(x => x.key.toString().includes(option.key as string)));
                setSelectedApplication('');
                setSelectedSubApplication('');
                setSelectedProcessStage('');
                setSelectedAlfaLavalPosition('');
                setSelectedProductLine('');
                setAlfaLavalPositions([]);
                setProcessStages([])
                setLocalStorageItem('position_subindustry', option.key as string);
                props.onIncompleteUnitConfiguration();
                break;
            case 'application':
                setSelectedApplication(option.key as string);
                setSubApplications(availableSubApplications?.filter(x => x.key.toString().includes(option.key as string)));
                setSelectedSubApplication('');
                setSelectedProcessStage('');
                setSelectedAlfaLavalPosition('');
                setSelectedProductLine('');
                setAlfaLavalPositions([]);
                setProcessStages([])
                setLocalStorageItem('position_application', option.key as string);
                props.onIncompleteUnitConfiguration();
                break;
            case 'subApplication':
                setSelectedSubApplication(option.key as string);
                setProductLines(availableProductLines?.filter(x => x.key.toString().includes(option.key as string)));
                setSelectedProcessStage('');
                setSelectedAlfaLavalPosition('');
                setSelectedProductLine('');
                setAlfaLavalPositions([]);
                setProcessStages([])
                setLocalStorageItem('position_subapplication', option.key as string);
                props.onIncompleteUnitConfiguration();
                break;
            case 'productLine':
                setSelectedProductLine(option.key as string);
                setAlfaLavalPositions([]);
                setProcessStages([])
                setSelectedAlfaLavalPosition('');
                setLocalStorageItem('position_productline', option.key as string);
                props.onIncompleteUnitConfiguration();
                break;
            case 'processStage':
                setSelectedProcessStage(option.key as string);
                if (option.key !== selectedProcessStage) {
                    setAlfaLavalPositions([]);
                }

                setSelectedAlfaLavalPosition('');
                setLocalStorageItem('position_processstage', option.key as string);
                props.onIncompleteUnitConfiguration();
                break;
            case 'alfaLavalPosition':
                setSelectedAlfaLavalPosition(option.key as string);
                setLocalStorageItem('position_alfalavalposition', option.key as string);
                break;
            default:
                break;
        }
    }

    return (
        <Stack>
            {
                isLoading &&
                <Spinner size={SpinnerSize.large} label="Loading configuration..." />
            }
            {
                !isLoading &&
                <>
                    <Label style={{ marginLeft: 20 }}>Select targeted position</Label>
                    <Stack horizontal styles={generalStackStyles(theme)} style={{ display: 'block' }}>
                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Industry</Label>
                            <Dropdown disabled={isLoading} options={industries!} onChange={(e, item) => handleChange(item, 'industry')} selectedKey={selectedIndustry} />
                        </Stack.Item>
                        {
                            selectedIndustry !== '' && selectedIndustry !== null &&
                            <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                                <Label>Sub Industry</Label>
                                <Dropdown disabled={isLoading} options={subIndustries!} onChange={(e, item) => handleChange(item, 'subIndustry')} selectedKey={selectedSubIndustry} />
                            </Stack.Item>
                        }
                        {
                            selectedSubIndustry !== '' && selectedSubIndustry !== null &&
                            <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                                <Label>Application</Label>
                                <Dropdown disabled={isLoading} options={applications!} onChange={(e, item) => handleChange(item, 'application')} selectedKey={selectedApplication} />
                            </Stack.Item>
                        }
                        {
                            selectedApplication !== '' && selectedApplication !== null &&
                            <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                                <Label>Sub application</Label>
                                <Dropdown disabled={isLoading} options={subApplications!} onChange={(e, item) => handleChange(item, 'subApplication')} selectedKey={selectedSubApplication} />
                            </Stack.Item>
                        }
                        {
                            selectedApplication !== '' && selectedApplication !== null && selectedSubApplication != '' && selectedSubApplication != null &&
                            <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                                <Label>Product line</Label>
                                <Dropdown disabled={isLoading} options={productLines!} onChange={(e, item) => handleChange(item, 'productLine')} selectedKey={selectedProductLine} />
                            </Stack.Item>
                        }
                    </Stack>
                </>
            }
            {
                !isLoading && processStages.length > 0 &&
                <>
                    <Label style={{ padding: '2px 20px' }}>Process stage</Label>
                    <Stack horizontal styles={generalStackStyles(theme)}>
                        {
                            processStages.map((stage, index) => {
                                return <>
                                    <Stack.Item key={`${stage}-${index}`} style={{ padding: 5 }}>
                                        {
                                            selectedProcessStage === selectedProductLine + '-' + stage ?
                                                <PrimaryButton
                                                    disabled={isLoading}
                                                    text={stage}
                                                    onClick={() => { handleChange({ key: selectedProductLine + '-' + stage, text: stage }, 'processStage') }}
                                                />
                                                :
                                                <DefaultButton
                                                    disabled={isLoading}
                                                    text={stage}
                                                    onClick={() => { handleChange({ key: selectedProductLine + '-' + stage, text: stage }, 'processStage') }}
                                                />
                                        }
                                    </Stack.Item>
                                </>
                            })
                        }
                    </Stack>
                </>
            }

            {
                !isLoading && alfaLavalPositions.length > 0 &&
                <>
                    <Label style={{ padding: '2px 20px' }}>Positions</Label>
                    <Stack horizontal styles={generalStackStyles(theme)}>
                        <Stack.Item grow>
                            <Stack horizontal>
                                {
                                    isLoadingPositions && !isLoading ?
                                        <Spinner label='Loading...' />
                                        :
                                        alfaLavalPositions.map((pos, index) => {
                                            return <>
                                                <Stack.Item key={`${pos}-${index}`} style={{ padding: 5 }}>
                                                    {
                                                        selectedAlfaLavalPosition === selectedProcessStage + '-' + pos ?
                                                            <PrimaryButton
                                                                disabled={isLoading}
                                                                text={pos}
                                                                onClick={() => { handleChange({ key: selectedProcessStage + '-' + pos, text: pos }, 'alfaLavalPosition'); props.onChange(selectedProcessStage + '-' + pos) }}
                                                            />
                                                            :
                                                            <DefaultButton
                                                                disabled={isLoading}
                                                                text={pos}
                                                                onClick={() => { handleChange({ key: selectedProcessStage + '-' + pos, text: pos }, 'alfaLavalPosition'); props.onChange(selectedProcessStage + '-' + pos) }}
                                                            />
                                                    }
                                                </Stack.Item>
                                            </>
                                        })
                                }
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </>
            }
        </Stack>
    )
}