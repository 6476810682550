import { Stack, Label, useTheme, MessageBar, MessageBarType } from "@fluentui/react"
import { Constants } from "../../../Models/Constants"
import { getFlowTypeUnits, getPreferredUnits } from "../../../Services/Global";
import { isRelevantInputField } from "../../../Utils/UnitConfigurationHelper";
import { useEffect, useState } from "react";
import { IMultiSectionUnitConfiguration, IUnitConfiguration } from "../../../Models/IUnitConfiguration";
import { IMultiSectionProperties } from "../../../Models/IMultiSectionProperties";
import React from "react";
import TextFieldNoScroll from "../TextBox/TextFieldNoScroll";

/**
 * ConditionalTextFieldInputs component
 *
 * @export
 * @param {*} {
 *     readOnly,
 *     originPage,
 *     unitConfiguration,
 *     propertySpecs,
 *     handleChange,
 *     hotMediaFlowrate,
 *     hotMediaTempIn,
 *     hotMediaTempOut,
 *     hotMediaPressureDrop,
 *     coldMediaFlowrate,
 *     coldMediaTempIn,
 *     coldMediaTempOut,
 *     coldMediaPressureDrop,
 *     hotMediaFlowrateError,
 *     hotMediaTempOutError,
 *     hotMediaTempInError,
 *     hotMediaPressureDropError,
 *     coldMediaFlowrateError,
 *     coldMediaTempInError,
 *     coldMediaTempOutError,
 *     coldMediaPressureDropError,
 *     addStyling,
 *     readOnlyFields,
 *     temperatureError
 * }
 * @return {*} 
 */
export const ConditionalTextFieldInputs: React.FC<{
    readOnly?: boolean,
    originPage: string,
    unitConfiguration: IUnitConfiguration | IMultiSectionUnitConfiguration,
    propertySpecs: IMultiSectionProperties[],
    handleChange: any,
    hotMediaFlowrate?: string,
    hotMediaTempIn?: string,
    hotMediaTempOut?: string,
    hotMediaPressureDrop?: string,
    coldMediaFlowrate?: string,
    coldMediaTempIn?: string,
    coldMediaTempOut?: string,
    coldMediaPressureDrop?: string,
    hotMediaFlowrateError?: string,
    hotMediaTempOutError?: string,
    hotMediaTempInError?: string,
    hotMediaPressureDropError?: string,
    coldMediaFlowrateError?: string,
    coldMediaTempInError?: string,
    coldMediaTempOutError?: string,
    coldMediaPressureDropError?: string,
    addStyling?: boolean,
    readOnlyFields?: string[],
    temperatureError?: any
}> = ({
    readOnly = false,
    originPage,
    unitConfiguration,
    propertySpecs,
    handleChange,
    hotMediaFlowrate,
    hotMediaTempIn,
    hotMediaTempOut,
    hotMediaPressureDrop,
    coldMediaFlowrate,
    coldMediaTempIn,
    coldMediaTempOut,
    coldMediaPressureDrop,
    hotMediaFlowrateError,
    hotMediaTempInError,
    hotMediaTempOutError,
    hotMediaPressureDropError,
    coldMediaTempInError,
    coldMediaTempOutError,
    coldMediaFlowrateError,
    coldMediaPressureDropError,
    addStyling,
    readOnlyFields,
    temperatureError
}) => {

        const [propertySpecifications, setPropertySpecifications] = useState(propertySpecs !== undefined ? propertySpecs : []);
        const unitOperationTargetValueProp = propertySpecs && propertySpecs.find(c => c.name === Constants.UnitOperationTargetValue);

        const shouldDisplayUnitOperationTargetValue = unitOperationTargetValueProp !== undefined && unitOperationTargetValueProp !== null;

        const [flowUnit, setFlowUnit] = useState('');
        const [hotProperties, setHotProperties] = useState<IMultiSectionProperties[]>([]);
        const [coldProperties, setColdProperties] = useState<IMultiSectionProperties[]>([]);
        const [shouldStyleHot, setShouldStyleHot] = useState(false);
        const [shouldStyleCold, setShouldStyleCold] = useState(false);

        let theme = useTheme();
        const warmStyling = {
            border: '2px solid ' + theme.palette.redDark,
            margin: 5,
            borderRadius: 5,
            padding: 5
        }

        const coldStyling = {
            border: '2px solid ' + theme.palette.themePrimary,
            margin: 5,
            borderRadius: 5,
            padding: 5
        }


        const preferredUnit = getPreferredUnits().preferredUnits;
        const temperatureUnit = preferredUnit === 'us' ? '°F' : '°C';

        const pressureUnit = preferredUnit === 'us' ? 'PSI' : 'kPa';

        useEffect(() => {
            if (unitConfiguration === undefined || unitConfiguration.flowType === null) return;

            let shouldStyleHot = false;
            let shouldStyleCold = false;

            if (!unitConfiguration.isMultiSection) {
                const hotInputs = unitConfiguration.inputs?.filter(x => x.startsWith('Hot'));
                const coldInputs = unitConfiguration.inputs?.filter(x => x.startsWith('Cold'));

                shouldStyleHot = hotInputs && hotInputs.length > 0 ? true : false;
                shouldStyleCold = coldInputs && coldInputs.length > 0 ? true : false;
            }
            else {
                const hotPropertySpecs = propertySpecs.filter(x => x.name.startsWith('Hot'));
                shouldStyleHot = hotPropertySpecs && hotPropertySpecs.length > 0 ? true : false;

                const coldPropertySpecs = propertySpecs.filter(x => x.name.startsWith('Cold'));
                shouldStyleCold = coldPropertySpecs && coldPropertySpecs.length > 0 ? true : false;
                setHotProperties(hotPropertySpecs);
                setColdProperties(coldPropertySpecs);

            }

            setFlowUnit(getFlowTypeUnits(unitConfiguration.flowType!));
            setShouldStyleHot(shouldStyleHot);
            setShouldStyleCold(shouldStyleCold);

        }, [unitConfiguration, propertySpecs])

        
        /**
         * Render error message
         *
         * @param {string} [error]
         * @return {*} 
         */
        function renderErrorMessage(error?: string) {

            if (error === undefined || error === '') return;
            const feedbackMessage: string = `Regarding the ${unitConfiguration.productLine} ${unitConfiguration.alfaLavalPosition},\nI wish for a wider design window.\nHere is what I would need:\n\n[Add your feedback here]\n\n`;

            return (
                <>
                    {/* TODO: The Mailto address should be updated when a distribution list has been created */}
                    <p>{error} Please <a style={{ color: theme.palette.themePrimary }} href={`mailto:magnus.hoffstein@alfalaval.com?subject=AI design wizard -EMELIE- Design Window Feedback&body=${encodeURIComponent(feedbackMessage)}`}
                        target="_blank"><b>send a mail with feedback</b></a> on which ranges you need for future implementations.</p>
                </>
            );
        }

        return (
            <Stack>
                {
                    (originPage == Constants.ModelDataSampleForm || originPage == Constants.DesignSessionReviewForm || (originPage == Constants.ProductDesignForm && !unitConfiguration.isMultiSection)) ?
                        <>
                            <Stack style={addStyling || shouldStyleHot ? warmStyling : {}}>
                                {/* Hot media mass flowrate */}
                                {
                                    isRelevantInputField(Constants.HotMediaFlowrate, unitConfiguration) &&
                                    <Stack.Item>
                                        <Label>{unitConfiguration.hotMediaType} flow rate</Label>
                                        {
                                            readOnlyFields?.includes(Constants.HotMediaFlowrate) ?
                                                <p>{hotMediaFlowrate}</p>
                                                :
                                                <TextFieldNoScroll type='number' readOnly={readOnly} step={1} onChange={handleChange} name={Constants.HotMediaFlowrate} value={hotMediaFlowrate} suffix={flowUnit} errorMessage={renderErrorMessage(hotMediaFlowrateError)} />
                                        }
                                    </Stack.Item>
                                }

                                {/* Hot inlet and outlet temperature */}
                                {
                                    // If both temperature in and out are readonly and relevant, then combine the two into one readonly property.
                                    isRelevantInputField(Constants.HotMediaTempIn, unitConfiguration) && isRelevantInputField(Constants.HotMediaTempOut, unitConfiguration)
                                        && readOnlyFields?.includes(Constants.HotMediaTempIn) && readOnlyFields.includes(Constants.HotMediaTempOut) ?
                                        <Stack.Item>
                                            <Label>{unitConfiguration.hotMediaType} temperature</Label>
                                            <Label style={{ fontSize: '1.2em', color: theme.palette.redDark }}>{hotMediaTempIn} {temperatureUnit}</Label>
                                        </Stack.Item>
                                        :
                                        <>
                                            {
                                                isRelevantInputField(Constants.HotMediaTempIn, unitConfiguration) &&
                                                <Stack.Item>
                                                    <Label>{unitConfiguration.hotMediaType} temperature inlet</Label>
                                                    {
                                                        readOnlyFields?.includes(Constants.HotMediaTempIn) ?
                                                            <Label style={{ fontSize: '1.2em' }}>{hotMediaTempIn} {temperatureUnit}</Label>
                                                            :
                                                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.HotMediaTempIn} value={hotMediaTempIn} suffix={temperatureUnit} errorMessage={renderErrorMessage(hotMediaTempInError)} />
                                                    }
                                                </Stack.Item>
                                            }

                                            {
                                                isRelevantInputField(Constants.HotMediaTempOut, unitConfiguration) &&
                                                <Stack.Item>
                                                    <Label>{unitConfiguration.hotMediaType} temperature outlet</Label>
                                                    {
                                                        readOnlyFields?.includes(Constants.HotMediaTempOut) ?
                                                            <Label style={{ fontSize: '1.2em' }}>{hotMediaTempOut} {temperatureUnit}</Label>
                                                            :
                                                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.HotMediaTempOut} value={hotMediaTempOut} suffix={temperatureUnit} errorMessage={renderErrorMessage(hotMediaTempOutError)} />
                                                    }
                                                </Stack.Item>
                                            }
                                        </>
                                }

                                {/* Hot media pressure drop */}
                                {
                                    isRelevantInputField(Constants.HotMediaPressureDrop, unitConfiguration) &&
                                    <Stack.Item>
                                        <Label>{unitConfiguration.hotMediaType} pressure drop</Label>
                                        {
                                            readOnlyFields?.includes(Constants.HotMediaPressureDrop) ?
                                                <Label style={{ fontSize: '1.2em' }}>{hotMediaPressureDrop} {pressureUnit}</Label>
                                                :
                                                <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.HotMediaPressureDrop} value={`${hotMediaPressureDrop}`} suffix={pressureUnit} errorMessage={renderErrorMessage(hotMediaPressureDropError)} />
                                        }
                                    </Stack.Item>
                                }
                            </Stack>

                            {/* Cold media mass flowrate */}
                            <Stack style={addStyling || shouldStyleCold ? coldStyling : {}}>
                                {
                                    isRelevantInputField(Constants.ColdMediaFlowrate, unitConfiguration) &&
                                    <Stack.Item>
                                        <Label>{unitConfiguration.coldMediaType} flow rate</Label>
                                        {
                                            readOnlyFields?.includes(Constants.ColdMediaFlowrate) ?
                                                <p>{coldMediaFlowrate} {flowUnit}</p>
                                                :
                                                <TextFieldNoScroll type='number' readOnly={readOnly} step={1} onChange={handleChange} name={Constants.ColdMediaFlowrate} value={coldMediaFlowrate} suffix={flowUnit} errorMessage={renderErrorMessage(coldMediaFlowrateError)}></TextFieldNoScroll>
                                        }
                                    </Stack.Item>
                                }

                                {/* Cold media inlet and outlet temperature */}
                                {
                                    // If both temperature in and out are readonly and relevant, then combine the two into one readonly property.
                                    isRelevantInputField(Constants.ColdMediaTempIn, unitConfiguration) && isRelevantInputField(Constants.ColdMediaTempOut, unitConfiguration)
                                        && readOnlyFields?.includes(Constants.ColdMediaTempIn) && readOnlyFields.includes(Constants.ColdMediaTempOut) ?
                                        <Stack.Item>
                                            <Label>{unitConfiguration.hotMediaType} temperature</Label>
                                            <Label style={{ fontSize: '1.2em', color: theme.palette.themePrimary }}>{coldMediaTempIn} {temperatureUnit}</Label>
                                        </Stack.Item>
                                        :
                                        <>
                                            {
                                                isRelevantInputField(Constants.ColdMediaTempIn, unitConfiguration) &&
                                                <Stack.Item>
                                                    <Label>{unitConfiguration.coldMediaType} temperature inlet</Label>
                                                    {
                                                        readOnlyFields?.includes(Constants.ColdMediaTempIn) ?
                                                            <Label style={{ fontSize: '1.2em' }}>{coldMediaTempIn} {temperatureUnit}</Label>
                                                            :
                                                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.ColdMediaTempIn} value={coldMediaTempIn} suffix={temperatureUnit} errorMessage={renderErrorMessage(coldMediaTempInError)} />
                                                    }
                                                </Stack.Item>
                                            }
                                            {
                                                isRelevantInputField(Constants.ColdMediaTempOut, unitConfiguration) &&

                                                <Stack.Item>
                                                    <Label>{unitConfiguration.coldMediaType} temperature outlet</Label>
                                                    {
                                                        readOnlyFields?.includes(Constants.ColdMediaTempOut) ?
                                                            <Label style={{ fontSize: '1.2em' }}>{coldMediaTempOut} {temperatureUnit}</Label>
                                                            :
                                                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.ColdMediaTempOut} value={coldMediaTempOut} suffix={temperatureUnit} errorMessage={renderErrorMessage(coldMediaTempOutError)}></TextFieldNoScroll>
                                                    }
                                                </Stack.Item>
                                            }
                                        </>
                                }

                                {/* Cold media pressure drop */}
                                {
                                    isRelevantInputField(Constants.ColdMediaPressureDrop, unitConfiguration) &&
                                    <Stack.Item>
                                        <Label>{unitConfiguration.coldMediaType} pressure drop</Label>
                                        {
                                            readOnlyFields?.includes(Constants.ColdMediaPressureDrop) ?
                                                <Label style={{ fontSize: '1.2em' }}>{coldMediaPressureDrop} {pressureUnit}</Label>
                                                :
                                                <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.ColdMediaPressureDrop} value={coldMediaPressureDrop} suffix={pressureUnit} errorMessage={renderErrorMessage(coldMediaPressureDropError)}></TextFieldNoScroll>
                                        }
                                    </Stack.Item>
                                }
                            </Stack>
                        </>
                        :
                        <>
                            <Stack style={addStyling && shouldStyleHot ? warmStyling : {}}>
                                {
                                    hotProperties && hotProperties.map((property, index) => (
                                        <Stack.Item key={index + property.name}>
                                            <Label> {property.mediaType} {property.property}</Label>
                                            <TextFieldNoScroll
                                                key={index + property.name}
                                                type='number' onChange={handleChange}
                                                readOnly={readOnly}
                                                step={0.1}
                                                name={property.name}
                                                suffix={property.flowUnit}
                                                // value={property.value}
                                                errorMessage={renderErrorMessage(property.errorMessage)}>
                                            </TextFieldNoScroll>
                                        </Stack.Item>
                                    ))
                                }
                            </Stack>

                            <Stack style={addStyling && shouldStyleCold ? coldStyling : {}}>
                                {
                                    coldProperties && coldProperties.map((property, index) => (
                                        <Stack.Item key={index + property.name}>
                                            <Label> {property.mediaType} {property.property}</Label>
                                            <TextFieldNoScroll
                                                key={index + property.name}
                                                type='number'
                                                onChange={handleChange}
                                                readOnly={readOnly}
                                                step={0.1}
                                                name={property.name}
                                                suffix={property.flowUnit}
                                                // value={property.value}
                                                errorMessage={renderErrorMessage(property.errorMessage)} >
                                            </TextFieldNoScroll>
                                        </Stack.Item>
                                    ))
                                }
                            </Stack>
                            <Stack>
                                {
                                    shouldDisplayUnitOperationTargetValue &&
                                    <Stack.Item>
                                        <Label>{unitOperationTargetValueProp?.property}</Label>
                                        <TextFieldNoScroll
                                            key={Constants.UnitOperationTargetValue}
                                            type='number'
                                            onChange={handleChange}
                                            readOnly={readOnly}
                                            step={0.1}
                                            name={unitOperationTargetValueProp?.name}
                                            suffix={unitOperationTargetValueProp?.flowUnit}
                                            // value={unitOperationTargetValueProp.value} 
                                            errorMessage={renderErrorMessage(unitOperationTargetValueProp?.errorMessage)}>
                                        </TextFieldNoScroll>
                                    </Stack.Item>
                                }
                            </Stack>

                        </>
                }
                {
                    temperatureError !== "" &&
                    <Stack.Item align='center'>
                        <MessageBar messageBarType={MessageBarType.error} messageBarIconProps={{ iconName: 'error' }} >
                            {renderErrorMessage(temperatureError)}
                        </MessageBar>
                    </Stack.Item>

                }
            </Stack>

        )
    }