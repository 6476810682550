import { Constants, PlatePackConfigurationConstants } from '../Models/Constants';
import { IPlatePackConfiguration } from '../Models/IPlatePackConfiguration';


export const basicPlatePackConfiguration: string[] = [
    PlatePackConfigurationConstants.cold_1_channels_per_pass_type_1,
    PlatePackConfigurationConstants.cold_1_passes,
    PlatePackConfigurationConstants.cold_plate_pattern_type_1,
    PlatePackConfigurationConstants.hot_1_channels_per_pass_type_1,
    PlatePackConfigurationConstants.hot_1_passes,
    PlatePackConfigurationConstants.hot_plate_pattern_type_1
]
export const varyingThetaPlatePackConfiguration: string[] = [
    PlatePackConfigurationConstants.cold_1_channels_per_pass_type_1,
    PlatePackConfigurationConstants.cold_1_channels_per_pass_type_2,
    PlatePackConfigurationConstants.cold_1_passes,
    PlatePackConfigurationConstants.cold_plate_pattern_type_1,
    PlatePackConfigurationConstants.cold_plate_pattern_type_2,
    PlatePackConfigurationConstants.hot_1_channels_per_pass_type_1,
    PlatePackConfigurationConstants.hot_1_channels_per_pass_type_2,
    PlatePackConfigurationConstants.hot_1_passes,
    PlatePackConfigurationConstants.hot_plate_pattern_type_1,
    PlatePackConfigurationConstants.hot_plate_pattern_type_2,
]
export const varyingChannelsPlatePackConfiguration: string[] = [
    PlatePackConfigurationConstants.cold_1_channels_per_pass_type_1,
    PlatePackConfigurationConstants.cold_1_passes,
    PlatePackConfigurationConstants.cold_2_channels_per_pass,
    PlatePackConfigurationConstants.cold_2_passes,
    PlatePackConfigurationConstants.cold_plate_pattern_type_1,
    PlatePackConfigurationConstants.hot_1_channels_per_pass_type_1,
    PlatePackConfigurationConstants.hot_1_passes,
    PlatePackConfigurationConstants.hot_2_channels_per_pass,
    PlatePackConfigurationConstants.hot_2_passes,
    PlatePackConfigurationConstants.hot_plate_pattern_type_1,
]

/**
 * Check the validity of the plate pack configuration for multi section unit configuration 
 *
 * @param {IPlatePackConfiguration} platePackConfig
 * @return {*} 
 */
export function checkPlatePackMultiSectionValidity(platePackConfigs: IPlatePackConfiguration[]) {
    let isValid = true;
    platePackConfigs.forEach((platePackConfig: any) => {
        switch (platePackConfig.type) {
            case Constants.BasicPass:
                basicLoop: for (let property of basicPlatePackConfiguration) {
                    if (platePackConfig[property] === undefined || platePackConfig[property] === null) {
                        isValid = false;
                        break basicLoop;
                    }
                }
                break;
            case Constants.VaryingTheta:
                varyingDataLoop: for (let property of varyingThetaPlatePackConfiguration) {
                    if (isValid === true && (platePackConfig[property] === undefined || platePackConfig[property] === null)) {
                        isValid = false;
                        break varyingDataLoop;
                    }
                }
                break;
            case Constants.VaryingChannelsPasses:
                varyingChannelLoop: for (let property of varyingChannelsPlatePackConfiguration) {
                    if (isValid === true && (platePackConfig[property] === undefined || platePackConfig[property] === null)) {
                        isValid = false;
                        break varyingChannelLoop;
                    }
                }
                break;
        }
    });
    return isValid;
}