import { getPreferredUnits } from "../Services/Global";

export function convertPoundsToKilograms(input: number, round: boolean = true) {
    if (round === true) {
        return Math.ceil((input * 0.45359237));
    }

    return (input * 0.45359237);
}

export function convertKilogramsToPunds(input: number, round: boolean = true) {
    if (round === true) {
        return Math.ceil((input * 2.2046226218));
    }

    return (input * 2.2046226218);
}

export function convertFahrenheitToCelsius(input: number, round: boolean = true) {
    if (round === true) {
        return Math.ceil((input - 32) * 0.5556);
    }
    return (input - 32) * 0.5556;
}

export function convertCelsiusToFahrenheit(input: number, round: boolean = false) {
    if (round === true) {
        return Math.ceil((input * 1.8) + 32);
    }

    return (input * 1.8) + 32;
}

export const TemperatureUnit = getPreferredUnits().preferredUnits === 'us' ? '°F' : '°C';
export const FlowUnit = getPreferredUnits().preferredUnits === 'us' ? 'lb/h' : 'kg/h';
export const PressureUnit = getPreferredUnits().preferredUnits === 'us' ? 'PSI' : 'kPa';

export function getFormattedDate(input: string) {
    let d = new Date(input);
    return `${d.toLocaleDateString('utc', { year: 'numeric', day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;
}