import React, { useEffect, useState } from 'react';
import Settings from '../../../Assets/SVG/setting.svg';
import logout from '../../../Assets/SVG/logout.svg';
import Logo from '../../../Assets/SVG/logo.svg';
import '../Navbar/navbar.scss';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { DefaultButton, IStackStyles, Overlay, Persona, PersonaSize, Stack, useTheme } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { getUserFirstname, getUserLastname, hasRole } from '../../../Services/Global';
import { Roles } from '../../../Models/Enums';

/**
 * Navbar component
 *
 * @param {*} { sellerFeatureActive, modelerFeatureActive }
 * @return {*} 
 */
export const Navbar: React.FC<{ sellerFeatureActive: boolean, modelerFeatureActive: boolean }> = (props) => {
  const isAuthenticated = useIsAuthenticated();

  let navigate = useNavigate();
  let theme = useTheme();
  const { instance } = useMsal();

  const [showDropdown, setShowDropdown] = useState(false);
  const [lastName, setLastname] = useState('');
  const [firstName, setFirstname] = useState('');

  // Styles
  const stackStyles: IStackStyles = {
    root: [
      {
        padding: 10,
        selectors: {
          ':hover': {
            background: theme.palette.neutralLight
          }
        }
      }
    ]
  }

  const menuStyles: IStackStyles = {
    root: [
      {
        padding: 10,
        selectors: {
          ':hover': {
            cursor: 'pointer',
            background: theme.palette.neutralLight
          }
        }
      }
    ]
  }

  
  /**
   * Handles logout
   *
   */
  function handleLogout() {
    instance.logoutRedirect().catch((e: any) => {
      console.error(e);
    });
  }

  useEffect(() => {
    setFirstname(getUserFirstname);
    setLastname(getUserLastname);
  }, [])

  return (
    <Stack>
      <Stack horizontal style={{ background: theme.palette.white, borderBottom: '1px solid #e1dfdd' }}>
        <Stack.Item grow={0} style={{ paddingLeft: 20, paddingTop: 10 }}>
          <img className='emelie-navbar--logo' src={Logo} alt='logo' />
        </Stack.Item>
        <Stack.Item grow={1} style={{ paddingTop: 20, marginLeft: 50 }}>
          <Stack horizontal>
            <div className='emelie-f-24--primary'>AI Design Wizard</div>
            {
              !process.env.REACT_APP_DEPLOYED_ENV?.includes('prod') &&
              <span style={{ paddingLeft: 10 }}>({process.env.REACT_APP_DEPLOYED_ENV})</span>
            }
          </Stack>
        </Stack.Item>
        {
          hasRole(...[Roles[Roles.BlindTestUser].toString()]) &&
          <Stack.Item grow={0} style={{ marginRight: 10 }}>
            <DefaultButton onClick={() => navigate('/blindtests')} style={{ marginTop: 15, borderRadius: 5 }}>Blind tests</DefaultButton>
          </Stack.Item>
        }
        {
          props.modelerFeatureActive && (hasRole(Roles[Roles.Modeler].toString()) ||hasRole(Roles[Roles.SME].toString())) &&
          <Stack.Item grow={0} style={{ marginRight: 10 }}>
            <DefaultButton onClick={() => navigate('/modeling/dashboard')} style={{ marginTop: 15, borderRadius: 5 }}>Modeling</DefaultButton>
          </Stack.Item>
        }
        {
          props.sellerFeatureActive && hasRole(...[Roles[Roles.Seller].toString()]) &&
          <Stack.Item grow={0} style={{ marginRight: 10 }}>
            <DefaultButton onClick={() => navigate('/dashboard')} style={{ marginTop: 15, borderRadius: 5 }}>Seller dashboard</DefaultButton>
          </Stack.Item>
        }
        {
          isAuthenticated &&
          <>
            <Stack.Item style={{ cursor: 'pointer', margin: '0px 5px' }}>

              <Stack style={{ marginTop: showDropdown ? 0 : 0, zIndex: 1 }} onClick={() => setShowDropdown(!showDropdown)} horizontal styles={stackStyles}>
                <Stack horizontal grow>
                  <Stack.Item grow style={{ height: 40 }}>
                    <Persona text={`${firstName} ${lastName}`} size={PersonaSize.size32} />
                  </Stack.Item>
                  {
                    showDropdown &&
                    <Overlay style={{
                      background: 'none',
                      cursor: 'initial',
                      zIndex: 10
                    }}>
                      <Stack horizontalAlign='stretch' style={{
                        background: theme.palette.white,
                        boxShadow: theme.effects.elevation16,
                        width: 200,
                        marginLeft: window.innerWidth - 210,
                        marginTop: 65,
                        borderRadius: 5
                      }}>
                        <Stack.Item styles={menuStyles} onClick={() => navigate('/admin')}>
                          <img src={Settings} alt='' style={{ float: 'left', padding: 5 }} />
                          <div style={{ padding: 5 }}>
                            Settings
                          </div>
                        </Stack.Item>
                        <Stack.Item styles={menuStyles} onClick={() => handleLogout()}>
                          <img src={logout} alt='' style={{ float: 'left', padding: 7 }} />
                          <div style={{ padding: 5 }}>
                            Logout
                          </div>
                        </Stack.Item>
                      </Stack>
                    </Overlay>
                  }
                </Stack>
              </Stack>
            </Stack.Item>


          </>
        }
      </Stack>
    </Stack >

  );
}