import { Dropdown, IDropdownOption, Label, Stack, TextField, useTheme } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { hoverableStackStyles, warmStyling, coldStyling } from '../../../../Models/StackStyling';
import { IPlatePackConfiguration } from '../../../../Models/IPlatePackConfiguration';
import { Constants } from '../../../../Models/Constants';

interface IFormValidation {
    config?: IPlatePackConfiguration,
    pheType?: string
}

/**
 * PHE Type Plate Pack Input Form component to display the form for PHE type and plate pack configuration input. 
 *
 * @param {*} { setConfig, setPheType, validateForm, shouldValidateFormInputs, pheTypes, existingConfig, existingPheType, isReadOnly, label }
 * @return {*} 
 */
export const PheTypePlatePackInputForm: React.FC<{
    setConfig: (config: IPlatePackConfiguration) => void,
    setPheType: (pheType: string) => void,
    validateForm: (valid: boolean) => void,
    shouldValidateFormInputs?: boolean,
    pheTypes?: IDropdownOption[],
    existingConfig?: IPlatePackConfiguration,
    existingPheType?: string,
    isReadOnly?: boolean,
    label?: string,
}> = (props) => {

    let theme = useTheme();

    const [grouping, setGrouping] = useState<string>(props.existingConfig?.type ?? 'Basic');

    const redStyle = { background: theme.palette.redDark, height: 10, width: 10, borderRadius: 4 };
    const blueStyle = { background: theme.palette.blue, height: 10, width: 10, borderRadius: 4 }
    const disabledStyle = { background: theme.palette.accent, height: 10, width: 10, borderRadius: 4 }
    const selectedStyle = { border: '2px solid ' + theme.palette.themePrimary }

    const [readOnly, setReadOnly] = useState(props.isReadOnly ?? false);

    // Default Basic type.
    const [platePackConfig, setPlatePackConfig] = useState<IPlatePackConfiguration>({ type: props.existingConfig?.type ?? 'Basic' });
    const [pheT, setPheT] = useState(props.existingPheType ?? '');

    // PHE Type options
    const pheTypeOps: IDropdownOption[] = [
        { key: 'H', text: 'H' },
        { key: 'L', text: 'L' },
        { key: 'LNa', text: 'LNa' },
        { key: 'LWi', text: 'LWi' },
        { key: 'M', text: 'M' },
        { key: 'MH', text: 'MH' },
        { key: 'ML', text: 'ML' },
        { key: 'HG', text: 'HG' },
        { key: 'HW', text: 'HW' },
        { key: 'LG', text: 'LG' },
        { key: 'LW', text: 'LW' },
        { key: 'MG', text: 'MG' },
        { key: 'MW', text: 'MW' },
    ]

    const [typeOptions, setTypeOptions] = useState<IDropdownOption[]>(pheTypeOps);

    const stackStyles = hoverableStackStyles(theme);
    const warmStyle = warmStyling(theme);
    const coldStyle = coldStyling(theme);

    useEffect(() => {
        if (props.existingConfig) {
            setPlatePackConfig(props.existingConfig ?? {});
            setReadOnly(props.isReadOnly ?? false);
            setGrouping(props.existingConfig.type ?? 'Basic');
            setPheT(props.existingPheType ?? '');
            props.setPheType(props.existingPheType ?? '');
        }
        else {
            validateInputs({ config: platePackConfig, pheType: pheT })
        }

        setReadOnly(props.isReadOnly ?? false);
        setPheT(props.existingPheType ?? '');
        props.setPheType(props.existingPheType ?? '');
    }, [props.existingConfig]);

    const changeGrouping = (type: string) => {
        if (readOnly) return;

        let plateP: IPlatePackConfiguration = { ...platePackConfig, type: type };
        validateInputs({ config: plateP, pheType: pheT });
        setPlatePackConfig(plateP);
        setGrouping(type);
        props.validateForm(false); // Reset form validation.
    }

    
    /**
     * Validates the form inputs and sets the form validation state. 
     *
     * @param {IFormValidation} formData
     */
    const validateInputs = (formData: IFormValidation) => {
        if (!readOnly) {
            let config = formData.config;
            let dropDownsValid = false;
            if (config?.type === Constants.VaryingTheta) {
                dropDownsValid =
                    (config.hot_plate_pattern_type_1 !== undefined && config.hot_plate_pattern_type_1 !== '')
                    && (config.hot_plate_pattern_type_2 !== undefined && config.hot_plate_pattern_type_2 !== '')
                    && (config.cold_plate_pattern_type_1 !== undefined && config.cold_plate_pattern_type_1 !== '')
                    && (config.cold_plate_pattern_type_2 !== undefined && config.cold_plate_pattern_type_2 !== ''
                        && (config.cold_1_channels_per_pass_type_1 !== undefined && config.cold_1_channels_per_pass_type_1 !== ''))
            } else if (config?.type === Constants.VaryingChannelsPasses) {
                dropDownsValid =
                    config.hot_plate_pattern_type_1 !== undefined && config.hot_plate_pattern_type_1 !== ''
                    && config.cold_plate_pattern_type_1 !== undefined && config.cold_plate_pattern_type_1 !== ''
            }
            else {
                dropDownsValid =
                    config?.hot_plate_pattern_type_1 !== undefined && config?.hot_plate_pattern_type_1 !== ''
                    && config?.cold_plate_pattern_type_1 !== undefined && config?.cold_plate_pattern_type_1 !== '';
            }

            if (props.shouldValidateFormInputs && document.forms[0] !== undefined) {
                let formValid = document.forms[0].checkValidity();
                props.validateForm(dropDownsValid && formData.pheType !== undefined && formData.pheType !== '' && formValid);
            }
            else {
                props.validateForm(dropDownsValid && formData.pheType !== undefined && formData.pheType !== '');
            }
        }
    }

    
    /**
     * Filters the type options based on the current value of the plate pack configuration.
     *
     * @param {IPlatePackConfiguration} currentValue
     */
    const filterTypeOptions = (currentValue: IPlatePackConfiguration) => {
        // if ML is picked on one side, only MH is allowed on the other and vice versa.

        let currentOptions = typeOptions;
        if (currentValue.cold_plate_pattern_type_1 === 'ML'
            || currentValue.cold_plate_pattern_type_2 === 'ML'
            || currentValue.hot_plate_pattern_type_2 === 'ML'
            || currentValue.hot_plate_pattern_type_1 === 'ML'
        ) {
            currentOptions.find(x => x.key === 'ML')!.disabled = true;
            setTypeOptions(currentOptions);
        }
        else {
            currentOptions.find(x => x.key === 'ML')!.disabled = false;
            setTypeOptions(currentOptions);
        }

        if (currentValue.cold_plate_pattern_type_1 === 'MH'
            || currentValue.cold_plate_pattern_type_2 === 'MH'
            || currentValue.hot_plate_pattern_type_2 === 'MH'
            || currentValue.hot_plate_pattern_type_1 === 'MH'
        ) {
            currentOptions.find(x => x.key === 'MH')!.disabled = true;
            setTypeOptions(currentOptions);
        }
        else {
            currentOptions.find(x => x.key === 'MH')!.disabled = false;
            setTypeOptions(currentOptions);
        }
    }

    
    /**
     * Handles the change event for the input fields. 
     *
     * @param {string} input
     * @param {string} target
     */
    const handleChange = (input: string, target: string) => {
        let currentValue: IPlatePackConfiguration = platePackConfig;
        let isPhe: boolean = false;

        switch (target) {
            case 'hot_1_passes':
                currentValue = { ...currentValue, hot_1_passes: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'hot_1_channels_per_pass_type_1':
                currentValue = { ...currentValue, hot_1_channels_per_pass_type_1: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'hot_1_channels_per_pass_type_2':
                currentValue = { ...currentValue, hot_1_channels_per_pass_type_2: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'hot_2_passes':
                currentValue = { ...currentValue, hot_2_passes: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'hot_2_channels_per_pass':
                currentValue = { ...currentValue, hot_2_channels_per_pass: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'hot_plate_pattern_type_1':
                currentValue = { ...currentValue, hot_plate_pattern_type_1: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'hot_plate_pattern_type_2':
                currentValue = { ...currentValue, hot_plate_pattern_type_2: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'cold_1_passes':
                currentValue = { ...currentValue, cold_1_passes: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'cold_1_channels_per_pass_type_1':
                currentValue = { ...currentValue, cold_1_channels_per_pass_type_1: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'cold_1_channels_per_pass_type_2':
                currentValue = { ...currentValue, cold_1_channels_per_pass_type_2: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'cold_2_passes':
                currentValue = { ...currentValue, cold_2_passes: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'cold_2_channels_per_pass':
                currentValue = { ...currentValue, cold_2_channels_per_pass: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'cold_plate_pattern_type_1':
                currentValue = { ...currentValue, cold_plate_pattern_type_1: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;
            case 'cold_plate_pattern_type_2':
                currentValue = { ...currentValue, cold_plate_pattern_type_2: input, type: grouping }
                setPlatePackConfig(currentValue);
                break;

            case 'pheType':
                setPheT(input);
                props.setPheType(props.pheTypes!.find(x => x.key.toString() === input)!.text);
                isPhe = true;
                break;
            default:
                break;
        }

        filterTypeOptions(currentValue);

        props.setConfig(currentValue);
        if (isPhe) {
            validateInputs({ config: currentValue, pheType: input });
        }
        else {
            validateInputs({ config: currentValue, pheType: pheT });
        }

    }

    return (
        <>
            <Stack.Item>
                <Label>PHE Type</Label>
                <Dropdown required disabled={readOnly} options={props.pheTypes!} onChange={(e, o) => handleChange(o!.key.toString(), 'pheType')} selectedKey={pheT} selectedKeys={[pheT]} />
            </Stack.Item>
            <Label>{props.label ?? 'Plate pack configuration'}</Label>
            <p>Type: {grouping}</p>
            {/* Basic selection */}
            <Stack horizontal>
                <Stack grow verticalAlign='center' horizontalAlign='center' styles={stackStyles} style={grouping === 'Basic' ? selectedStyle : { border: '2px solid white' }} onClick={() => changeGrouping('Basic')}>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} style={{ marginTop: 5 }}>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                    </Stack>
                </Stack>

                {/* Varying theta */}
                <Stack grow verticalAlign='center' horizontalAlign='center' styles={stackStyles} style={grouping === 'Varying theta' ? selectedStyle : {}} onClick={() => changeGrouping('Varying theta')}>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>

                        <Stack.Item>
                            <div style={{ background: theme.palette.white, height: 2, width: 2 }} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} style={{ marginTop: 5 }}>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={{ background: theme.palette.white, height: 2, width: 2 }} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                    </Stack>
                </Stack>

                {/* Varying channels in passes */}
                <Stack grow verticalAlign='center' horizontalAlign='center' styles={stackStyles} style={grouping === 'Varying channels in passes' ? selectedStyle : {}} onClick={() => changeGrouping('Varying channels in passes')}>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={redStyle} />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }} style={{ marginTop: 5 }}>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                        <Stack.Item>
                            <div style={blueStyle} />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>

            {
                grouping === 'Basic' &&
                <Stack horizontal>
                    <Stack.Item grow>
                        <Stack horizontal grow styles={warmStyle}>
                            <Stack.Item>
                                <Label>Pass</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.hot_1_passes ?? ''} onChange={(e, o) => handleChange(o!, 'hot_1_passes')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24, padding: 10 }}>*</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.hot_1_channels_per_pass_type_1 ?? ''} onChange={(e, o) => handleChange(o!, 'hot_1_channels_per_pass_type_1')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 8 }}>
                                <Label>Type</Label>
                                <Dropdown required disabled={readOnly} options={typeOptions} onChange={(e, o) => handleChange(o?.text!, 'hot_plate_pattern_type_1')} style={{ width: 75, fontSize: 20 }} selectedKey={platePackConfig.hot_plate_pattern_type_1 ?? ''} />
                            </Stack.Item>
                        </Stack>

                        <Stack horizontal grow styles={coldStyle}>
                            <Stack.Item>
                                <Label>Pass</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.cold_1_passes ?? ''} onChange={(e, o) => handleChange(o!, 'cold_1_passes')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24, padding: 10 }}>*</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.cold_1_channels_per_pass_type_1 ?? ''} onChange={(e, o) => handleChange(o!, 'cold_1_channels_per_pass_type_1')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 8 }}>
                                <Label>Type</Label>
                                <Dropdown required disabled={readOnly} options={typeOptions} onChange={(e, o) => handleChange(o?.text!, 'cold_plate_pattern_type_1')} selectedKey={platePackConfig.cold_plate_pattern_type_1 ?? ''} style={{ width: 75, fontSize: 20 }} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            }


            {
                grouping === 'Varying theta' &&
                <Stack horizontal>
                    <Stack.Item grow>
                        <Stack horizontal grow styles={warmStyle}>
                            <Stack.Item>
                                <Label>Pass1</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.hot_1_passes ?? ''} onChange={(e, o) => handleChange(o!, 'hot_1_passes')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24, padding: 10 }}>*</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan1</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.hot_1_channels_per_pass_type_1 ?? ''} onChange={(e, o) => handleChange(o!, 'hot_1_channels_per_pass_type_1')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 8 }}>
                                <Label>Type1</Label>
                                <Dropdown required disabled={readOnly} options={typeOptions} onChange={(e, o) => handleChange(o?.text!, 'hot_plate_pattern_type_1')} selectedKey={platePackConfig.hot_plate_pattern_type_1 ?? ''} style={{ width: 75, fontSize: 20 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24, padding: 2 }}>+</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan2</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.hot_1_channels_per_pass_type_2 ?? ''} onChange={(e, o) => handleChange(o!, 'hot_1_channels_per_pass_type_2')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 8 }}>
                                <Label>Type2</Label>
                                <Dropdown required disabled={readOnly} options={typeOptions} onChange={(e, o) => handleChange(o?.text!, 'hot_plate_pattern_type_2')} selectedKey={platePackConfig.hot_plate_pattern_type_2 ?? ''} style={{ width: 75, fontSize: 20 }} />
                            </Stack.Item>
                        </Stack>

                        <Stack horizontal grow styles={coldStyle}>
                            <Stack.Item>
                                <Label>Pass1</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.cold_1_passes ?? ''} onChange={(e, o) => handleChange(o!, 'cold_1_passes')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24, padding: 10 }}>*</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan1</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.cold_1_channels_per_pass_type_1 ?? ''} onChange={(e, o) => handleChange(o!, 'cold_1_channels_per_pass_type_1')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 8 }}>
                                <Label>Type1</Label>
                                <Dropdown required disabled={readOnly} options={typeOptions} onChange={(e, o) => handleChange(o?.text!, 'cold_plate_pattern_type_1')} selectedKey={platePackConfig.cold_plate_pattern_type_1 ?? ''} style={{ width: 75, fontSize: 20 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24, padding: 2 }}>+</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan2</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.cold_1_channels_per_pass_type_2 ?? ''} onChange={(e, o) => handleChange(o!, 'cold_1_channels_per_pass_type_2')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 8 }}>
                                <Label>Type2</Label>
                                <Dropdown required disabled={readOnly} options={typeOptions} onChange={(e, o) => handleChange(o?.text!, 'cold_plate_pattern_type_2')} selectedKey={platePackConfig.cold_plate_pattern_type_2 ?? ''} style={{ width: 75, fontSize: 20 }} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            }

            {
                grouping === 'Varying channels in passes' &&
                <Stack horizontal>
                    <Stack.Item grow>
                        <Stack horizontal grow styles={warmStyle}>
                            <Stack.Item>
                                <Label>Pass1</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.hot_1_passes ?? ''} onChange={(e, o) => handleChange(o!, 'hot_1_passes')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24 }}>*</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan1</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.hot_1_channels_per_pass_type_1 ?? ''} onChange={(e, o) => handleChange(o!, 'hot_1_channels_per_pass_type_1')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24 }}>+</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Pass2</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.hot_2_passes ?? ''} onChange={(e, o) => handleChange(o!, 'hot_2_passes')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24 }}>*</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan2</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.hot_2_channels_per_pass ?? ''} onChange={(e, o) => handleChange(o!, 'hot_2_channels_per_pass')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 8 }}>
                                <Label>Type1</Label>
                                <Dropdown required disabled={readOnly} options={typeOptions} onChange={(e, o) => handleChange(o?.text!, 'hot_plate_pattern_type_1')} selectedKey={platePackConfig.hot_plate_pattern_type_1 ?? ''} style={{ width: 75, fontSize: 20 }} />
                            </Stack.Item>
                        </Stack>

                        <Stack horizontal grow styles={coldStyle}>
                            <Stack.Item>
                                <Label>Pass1</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.cold_1_passes ?? ''} onChange={(e, o) => handleChange(o!, 'cold_1_passes')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24 }}>*</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan1</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.cold_1_channels_per_pass_type_1 ?? ''} onChange={(e, o) => handleChange(o!, 'cold_1_channels_per_pass_type_1')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24 }}>+</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Pass2</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.cold_2_passes ?? ''} onChange={(e, o) => handleChange(o!, 'cold_2_passes')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item grow align='center'>
                                <Label style={{ textAlign: 'center', fontSize: 24 }}>*</Label>
                            </Stack.Item>
                            <Stack.Item>
                                <Label>Chan2</Label>
                                <TextField required readOnly={readOnly} type='number' value={platePackConfig.cold_2_channels_per_pass ?? ''} onChange={(e, o) => handleChange(o!, 'cold_2_channels_per_pass')} style={{ width: 50, height: 30 }} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 8 }}>
                                <Label>Type1</Label>
                                <Dropdown required disabled={readOnly} options={typeOptions} onChange={(e, o) => handleChange(o?.text!, 'cold_plate_pattern_type_1')} selectedKey={platePackConfig.cold_plate_pattern_type_1 ?? ''} style={{ width: 75, fontSize: 20 }} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            }
        </>
    )

}
