import React from 'react'
import '../../Tooltip/Tooltip-1/Tooltip1.scss';
import '../../Tooltip/Tooltip-8/Tooltip8.scss';

export default function Tooltip8() {
  return (
    <div className="tooltip1">
    <div className="emelie-tooltip emelie-design--tooltip-8 tooltip-8">8</div>
    <div className="emelie-tooltip emelie-tooltip-8">8</div>
    <div className="emelie-tooltip--property tooltiptext">
      <div className="tooltiptext__img">
       
      </div>
      <div className="tooltiptext__content">
        
      </div>
    </div>
  </div>
  )
}
