import React from "react";
import info from "../../../Assets/SVG/info.svg";
import "./Tooltip.scss";

export default function (props: any) {
  return (
    <div className="tooltip">
      <img src={info} alt="info" />
      <span className="tooltiptext">{props.name}</span>
    </div>
  );
}
