import React from 'react'
import '../../Tooltip/Tooltip-1/Tooltip1.scss';
import '../../Tooltip/Tooltip-7/Tooltip7.scss';

export default function Tooltip7() {
  return (
    <div className="tooltip1">
    <div className="emelie-tooltip emelie-design--tooltip-7">7</div>
    <div className="emelie-tooltip--property tooltiptext">
      <div className="tooltiptext--img">
       
      </div>
      <div className="tooltiptext--content">
        
      </div>
    </div>
  </div>
  )
}
