import React from "react";
import "./Tooltip1.scss";
import MilkCooler from "../../../../Assets/PNG/milk cooler.png";
import "../Tooltip-1/Tooltip1.scss";

export default function Tooltip1() {
  return (
    <div className="tooltip1">
      <div className=" emelie-tooltip emelie-design--tooltip-1">1</div>
      <div className="emelie-tooltip--property tooltiptext">
        <div className="tooltiptext--img">
          <img src={MilkCooler} alt="Milkcooler" />
        </div>
        <div className="tooltiptext--content">
          <div className="tooltiptext--title">1. Milk Reception</div>
          <div className="tooltiptext--para">
            Incoming milk is cooled to storage temperature in a milk cooler.
            It’s important that it is cooled immediately upon arrival (max. 4°C)
            to prevent bacteria from multiplying during storage. Because of the
            low temperatures, NBRB2 gaskets may be an economical choice here.
          </div>
        </div>
      </div>
    </div>
  );
}
