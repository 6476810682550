import { IEffects, IStackItemStyles, ITheme } from "@fluentui/react";


export const generalStackStyles = (theme: ITheme, elevation: string = theme.effects.elevation4): IStackItemStyles => {
    const divStyles: IStackItemStyles = {
        root: [
            {
                background: theme.palette.white,
                boxShadow: elevation,
                padding: 15,
                margin: 15,
                borderRadius: 5
            }
        ]
    }
    return divStyles;
}

export const informationLabelStyle = (theme: ITheme, color?: string): IStackItemStyles => {
    const divStyles: IStackItemStyles = {
        root: [
            {
                background: theme.palette.neutralLighter,
                boxShadow: theme.effects.elevation4,
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 5,
                paddingTop: 5,
                color: color ?? theme.palette.themeDark
            }
        ]
    }
    return divStyles;
}

export const warmStyling = (theme: ITheme): IStackItemStyles => {
    const divStyles: IStackItemStyles = {
        root: [{
            border: "2px solid " + theme.palette.redDark,
            margin: 5,
            borderRadius: 5,
            padding: 5
        }
        ]
    }

    return divStyles;
}

export const coldStyling = (theme: ITheme): IStackItemStyles => {
    const divStyles: IStackItemStyles = {
        root: [
            {
                border: "2px solid " + theme.palette.themePrimary,
                margin: 5,
                borderRadius: 5,
                padding: 5
            }
        ]
    }

    return divStyles;
}

export const hoverableStackStyles = (theme: ITheme): IStackItemStyles => {
    const divStyles: IStackItemStyles = {
        root: [
            {
                background: theme.palette.white,
                boxShadow: theme.effects.elevation4,
                padding: 15,
                margin: 15,
                borderRadius: 5,
                transition: '150ms',
                selectors: {
                    ':hover': {
                        boxShadow: theme.effects.elevation16,
                        transition: '50ms',
                        cursor: 'pointer',
                        background: 'linear-gradient(270deg, #e8f6ff, #fff)',
                    }
                }
            }
        ]
    }
    return divStyles;
}