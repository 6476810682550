import React from 'react'
import '../../Tooltip/Tooltip-1/Tooltip1.scss';
import '../../Tooltip/Tooltip-6/Tooltip6.scss';

export default function Tooltip6() {
  return (
    <div className="tooltip1">
    <div className="emelie-tooltip emelie-design--tooltip-6">6</div>
    <div className="emelie-tooltip--property tooltiptext">
      <div className="tooltiptext--img">
       
      </div>
      <div className="tooltiptext--content">
      
      </div>
    </div>
  </div>
  )
}
