import { IDesignSession } from './IDesignSession';

export interface IBlindTest {
    id: number;
    user: string;
    createdDate: Date;
    modified: Date;
    status: BlindTestStatus;
    inProgress: boolean;
    blindTestSetId: string;
    positionDesignSessions: IDesignSession[];
}

export enum BlindTestStatus {
    New = 'New',
    Draft = 'Draft',
    Published = 'Published',
    Archived = 'Completed'
}


export interface GroupedSessions {
    [sectionId: string]: number
}
export interface CountSessions {
    ToDo: number,
    Done: number,
    BlindTestDataSetId: string
}