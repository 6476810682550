import { FontSizes, Stack, useTheme } from "@fluentui/react" // Importing necessary components and hooks from Fluent UI
import { hoverableStackStyles } from "../../../Models/StackStyling" // Importing custom stack styles

// Defining the PositionBadge component with its props
export const PositionBadge: React.FC<{
    name: string, // Name to be displayed
    type: string, // Type to be displayed in bold
    selected?: boolean, // Optional: Indicates if the badge is selected
    disabled?: boolean, // Optional: Indicates if the badge is disabled
    background?: string, // Optional: Background color of the badge
    fontColor?: string, // Optional: Font color of the badge
    onClick?: () => void // Optional: Click handler function
}> = (props) => {
    // Getting the current theme
    let theme = useTheme();

    return (
        // Main container stack with horizontal alignment and click handler
        <Stack horizontal onClick={props.onClick} styles={hoverableStackStyles(theme)} style={{ padding: 4, background: props.background, color: props.fontColor }}>
            <Stack.Item>
                {/* Paragraph to display name and type with custom font size */}
                <p style={{ fontSize: FontSizes.small }}>
                    {props.name} <strong>{props.type}</strong>
                </p>
            </Stack.Item>
        </Stack>
    )
}