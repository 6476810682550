import React from 'react'
import '../../Tooltip/Tooltip-1/Tooltip1.scss';
import '../../Tooltip/Tooltip-9/Tooltip9.scss';
export default function Tooltip9() {
  return (
    <div className="tooltip1">
    <div className="emelie-tooltip emelie-design--tooltip-9">9</div>
    <div className="emelie-tooltip--property tooltiptext">
      <div className="tooltiptext--img">
       
      </div>
      <div className="tooltiptext--content">
        
      </div>
    </div>
  </div>
  )
}
