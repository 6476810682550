import { IMsalContext, useMsal } from "@azure/msal-react";
import { getTheme, IStackItemStyles, Label, Stack } from "@fluentui/react"
import { useEffect, useMemo, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ApiService } from "../../../Services/ApiService";
import { IUnitConfiguration } from "../../../Models/IUnitConfiguration";

/**
 * ProductDesigns component
 *
 * @return {*} 
 */
export const ProductDesigns = () => {
    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const location = useLocation();
    let theme = getTheme();
    let navigate = useNavigate();

    const [industry, setIndustry] = useState<string>('');
    const [subIndustry, setSubIndustry] = useState<string>('');
    const [application, setApplication] = useState<string>('');
    const [subApplication, setSubApplication] = useState<string>('');
    const [unitConfiguration, setUnitConfiguration] = useState<IUnitConfiguration[]>();
    const [productLine, setProductLine] = useState<string>('');
    const [alfaLavalPosition, setAlfaLavalPosition] = useState<string>('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setIndustry(queryParams.get('industry') as string);
        setSubIndustry(queryParams.get('subindustry') as string);
        setApplication(queryParams.get('application') as string);
        setSubApplication(queryParams.get('subapplication') as string);
        setProductLine(queryParams.get('productline') as string);
        setAlfaLavalPosition(queryParams.get('alfalavalposition') as string);

        async function getDesigns() {
            let ind = queryParams.get('industry') as string;
            let subInd = queryParams.get('subindustry') as string;
            let app = queryParams.get('application') as string;
            let subApp = queryParams.get('subapplication') as string;
            let alpos = queryParams.get('alfalavalposition') as string;

            const response = await apiService.getAsync(`settings/unitconfiguration/${ind}/${subInd}/${app}/${subApp}/${alpos}`);
            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const result = await response.json();
            setUnitConfiguration(result);
        }

        getDesigns();
    }, []);

    const divStyles: IStackItemStyles = {
        root: [
            {
                background: theme.palette.white,
                boxShadow: theme.effects.elevation4,
                padding: 15,
                margin: 15,
                borderRadius: 5,
                transition: '150ms',
                selectors: {
                    ':hover': {
                        boxShadow: theme.effects.elevation16,
                        transition: '50ms',
                        cursor: 'pointer'
                    }
                }
            }
        ]
    }


    return (
        <Stack style={{ padding: 5, paddingTop: 10 }}>
            <Stack horizontal horizontalAlign='center'>
                <Stack.Item>
                    <h1>Available designs</h1>
                </Stack.Item>
            </Stack>

            <Stack horizontal>
                <Stack.Item>
                    <p>
                        {industry} / {subIndustry} / {application} / {subApplication}
                    </p>
                </Stack.Item>
            </Stack>

            <Stack horizontal horizontalAlign='center'>
                {
                    unitConfiguration?.map((item, index) => {
                        return (
                            <Stack.Item align='center' key={index} styles={divStyles} style={{ width: 300, maxHeight: 201 }} onClick={() => { navigate(`/productdesigns/productdesignform`, { state: item }) }}>
                                <Stack.Item style={{ cursor: 'pointer' }}>
                                    <Label>{item.alfaLavalPosition}</Label>
                                    <p style={{ position: 'absolute', marginTop: -8, fontSize: 12 }}>{item.productLine}</p>
                                </Stack.Item>
                            </Stack.Item>
                        )
                    })
                }
            </Stack>
        </Stack>
    )
}