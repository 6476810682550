import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    Button,
} from "@fluentui/react-components";
import React from "react";

interface IALConfirmDialogProps {
    open: boolean;
    acceptText?: string;
    onAccept?: () => any;
    declineText: string;
    onDecline: any;
    title?: string;
    content?: any;
    content2?: any;
    onChange?: (value: any) => void;
    disableAcceptButton?: boolean;
    bodyStyle?: any;
}

/**
 * Confirmation Dialog
 *
 * @param {IALConfirmDialogProps} props
 * @return {*} 
 */
export const ConfirmationDialog: React.FC<IALConfirmDialogProps> = (props: IALConfirmDialogProps) => {

    return (
        <Dialog open={props.open}>
            <DialogSurface >
                <DialogBody style={props?.bodyStyle ?? {}}>
                    <div>
                        {
                            props.title &&
                            <DialogTitle>{props.title}</DialogTitle>
                        }
                        {
                            props.content
                        }
                    </div>
                    <div>
                        {
                            props.content2
                        }
                    </div>
                </DialogBody>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" onClick={() => props.onDecline()} >{props.declineText}</Button>
                    </DialogTrigger>
                    {
                        props.onAccept &&
                        <Button appearance="primary" disabled={props.disableAcceptButton} onClick={() => typeof props.onAccept === 'function' && props.onAccept()} > {props.acceptText}</Button>
                    }
                </DialogActions>

            </DialogSurface>
        </Dialog>
    );
};