import React from 'react'
import MilkCooler from "../../../../Assets/PNG/milk cooler.png";
import Thermization from "../../../../Assets/PNG/Thermization.png";
import '../../Tooltip/Tooltip-1/Tooltip1.scss';
import '../../Tooltip/Tooltip-2/Tooltip2.scss';
export default function Tooltip2() {
  return (
    <div className="tooltip1">
      <div className="emelie-tooltip emelie-design--tooltip-2">2</div>
      <div className="emelie-tooltip--property tooltiptext">
        <div className="tooltiptext--img">
          <img src={Thermization} alt="Milkcooler" />
        </div>
        <div className="tooltiptext--content">
          <div className="tooltiptext--title">1. Milk Reception</div>
          <div className="tooltiptext--para">
            Incoming milk is cooled to storage temperature in a milk cooler.
            It’s important that it is cooled immediately upon arrival (max. 4°C)
            to prevent bacteria from multiplying during storage. Because of the
            low temperatures, NBRB2 gaskets may be an economical choice here.
          </div>
        </div>
      </div>
    </div>
  )
}
