import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { AuthConfig } from "./AuthConfig";
import { MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { DefaultPalette, IStackItemStyles, IStackTokens, PrimaryButton, Stack } from '@fluentui/react';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

const msalInstance = new PublicClientApplication(AuthConfig);

const stackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    background: DefaultPalette.yellowDark,
    color: DefaultPalette.white,
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'center'
  },
};
const containerStackTokens: IStackTokens = { childrenGap: 50 };
const loginStyle: IStackItemStyles = {
  root: {
    alignItems: 'center',
    color: DefaultPalette.white,
    display: 'flex',
    justifyContent: 'center',
  },
};
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <FluentProvider theme={webLightTheme}>
          <App />
        </FluentProvider>
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <Stack tokens={containerStackTokens}>
          <Stack.Item grow={4} styles={stackItemStyles}>
            <h4>You need to be signed in to access this content.</h4>
          </Stack.Item>
          <Stack.Item grow={3} styles={loginStyle}>
            <PrimaryButton
              text="Log in"
              onClick={() => { document.location.reload() }}
            />
          </Stack.Item>
        </Stack>
      </UnauthenticatedTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
