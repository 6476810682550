import React from 'react'
import '../../Tooltip/Tooltip-1/Tooltip1.scss';
import '../../Tooltip/Tooltip-3/Tooltip3.scss';

export default function Tooltip3() {
  return (
    <div className="tooltip1">
      <div className="emelie-tooltip emelie-design--tooltip-3">3</div>
      <div className="emelie-tooltip--property tooltiptext">
        <div className="tooltiptext--img">
          {/* <img src={Thermization} alt="Milkcooler" /> */}
        </div>
      </div>
    </div>
  )
}
