import DashboardImg from "../../../Assets/SVG/undraw_calculator.svg";
import HistoryList from "../HistoryList/HistoryList";
import { useNavigate } from 'react-router';
import { IMsalContext, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { ApiService } from "../../../Services/ApiService";
import { IUserSettings } from "../../../Models/IUserSettings";
import { hasRole, setUserSettings } from "../../../Services/Global";
import "../../Atom/ButtonProps/ButtonProps";
import "./Dashboard.scss";
import { FontSizes, PrimaryButton, Stack, useTheme } from "@fluentui/react";
import { Roles } from "../../../Models/Enums";
import { generalStackStyles } from "../../../Models/StackStyling";

/**
 * Dashboard component for displaying the dashboard 
 *
 * @return {*} 
 */
export const Dashboard: React.FC<{}> = () => {

  let navigate = useNavigate();
  let theme = useTheme();

  const ctx: IMsalContext = useMsal();
  const apiService: ApiService = new ApiService(ctx);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getUserSettings() {
      const response = await apiService.getAsync('settings');
      if (response.status >= 400) {
        const errMsg = `There was an issue getting user settings. Status code: ${response.status}`;
        throw new Error(errMsg);
      }
      const settings: IUserSettings = await response.json();
      setUserSettings(settings);
      setIsLoading(false);
    }

    getUserSettings();
  }, [])


  return (
    <Stack>
      <Stack.Item style={{ padding: 20 }}>
        <h2>Design</h2>
      </Stack.Item>
      <Stack styles={generalStackStyles(theme)} style={{minHeight: 200}}>

        <Stack.Item style={{paddingBottom: 7}}>
          <h3>EMELIE</h3>
        </Stack.Item>

        <Stack horizontal verticalAlign='center'>
          <Stack.Item style={{ fontSize: FontSizes.large }} grow>
            <p>
              Start designing the exact heat exchanger for your customer with a few
              parameters.
              {/* <br />You can also check the previous design in the past list. */}
            </p>
          
            <PrimaryButton text='Start' iconProps={{iconName: 'Forward'}} disabled={isLoading} style={{marginTop: 25}} styles={{
              flexContainer:{
                flexDirection: 'row-reverse'
              }
            }} className="emelieBtn" onClick={() => {
              navigate("/design");
            }}/>
          </Stack.Item>
          <Stack.Item>
            <div style={{marginTop: -90}}>
              <img src={DashboardImg} alt="dashboardImg" />
            </div>
          </Stack.Item>
        </Stack>
      </Stack>

      <HistoryList />

    </Stack>
  );
}
