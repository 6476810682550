import { Checkbox, DefaultButton, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, getTheme } from "@fluentui/react";
import { IMultiSectionUnitConfiguration, IPropertyRange, IUnitBaseConfiguration } from "../../../Models/IUnitConfiguration";
import { generalStackStyles } from "../../../Models/StackStyling";
import { Constants, PropertyConstants } from "../../../Models/Constants";
import { useEffect, useMemo, useState } from "react";
import { ApiService } from "../../../Services/ApiService";
import { useMsal } from "@azure/msal-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IUnitPropertySpecification } from "../../../Models/IUnitPropertySpecification";
import { getFlowTypeUnits, getPreferredUnits, setTemporaryState } from "../../../Services/Global";

/**
 * Property Range Selection component for setting the range of properties for a unit configuration 
 *
 * @return {*} 
 */
export const PropertyRangeSelection: React.FC<{}> = (props) => {
    let ctx = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const [isSaving, setIsSaving] = useState(false);
    const [userInputs, setUserInputs] = useState<IPropertyRange[]>();
    const [saveMessage, setSaveMessage] = useState<string>();
    const [unitConfiguration, setStateUnitConfiguration] = useState<IUnitBaseConfiguration>();
    const state: any = useLocation().state;

    let navigate = useNavigate();
    const theme = getTheme();
    let params = useParams();

    const preferredUnit = getPreferredUnits().preferredUnits;
    const temperatureUnit = preferredUnit === 'us' ? '°F' : '°C';
    const pressureUnit = preferredUnit === 'us' ? 'PSI' : 'kPa';

    const [unitConfig] = useState<IMultiSectionUnitConfiguration>({
        industry: params.i,
        subIndustry: params.si,
        application: params.a,
        subApplication: params.sa,
        processStage: params.ps,
        productLine: params.pl,
        alfaLavalPosition: params.alp,
        sectionCount: Number.parseInt(params.sc as string),
        inputs: params.ip ? params.ip.split(',') : [],
        flowType: state.unitConfiguration.flowType
    });

    useEffect(() => {
        
        // Set the inputs for the unit configuration
        async function setInputs() {
            let inputs: IPropertyRange[] = [];
            const unitconfiguration = state.unitConfiguration as IUnitBaseConfiguration;
            setStateUnitConfiguration(unitconfiguration);

            if (unitConfig.sectionCount === 1) {
                const props = await getRangeValidations(unitconfiguration);
                unitConfig.inputs?.forEach((c, index) => {
                    const currProp = props.find((x: any) => x.propertyName == c);
                    if (currProp) {
                        inputs.push(currProp);
                    }
                    else {
                        inputs.push({ propertyName: c });
                    }
                });
                setUserInputs(inputs);
            }
            else {
                getUnitPropertySpecification();
            }
        }

        // Get the range validations for the properties of the unit configuration
        async function getRangeValidations(unitInput: IUnitBaseConfiguration) {
            const response = await apiService.getAsync(`settings/rangeValidation`, unitInput);
            if (!response.ok) {
                throw new Error(`Could not get range configurations from API. ${response.status} - ${response.statusText}`);
            }
            const result = await response.json();
            return result;
        }

        // Get the unit property specification for the unit configuration
        async function getUnitPropertySpecification() {
            const response = await apiService.getAsync("settings/unitpropertyspecifications", unitConfig);
            if (!response.ok) {
                if (response.status === 404) {
                    return;
                }
                throw new Error(`${response.status}`);
            }

            try {
                const result: IUnitPropertySpecification = await response.json();
                const unitconfiguration = state.unitConfiguration as IUnitBaseConfiguration;

                if (result) {

                    let inputs: IPropertyRange[] = [];
                    result.userInputProperties.push(PropertyConstants.TempApproachHotInlet);
                    result.userInputProperties.push(PropertyConstants.TempApproachHotOutlet);
                    result.userInputProperties.push(PropertyConstants.DeltaTemperatureColdSide);
                    result.userInputProperties.push(PropertyConstants.DeltaTemperatureHotSide);
                    result.userInputProperties?.forEach((c) => {
                        const currProp = unitconfiguration.properties?.find(x => x.propertyName == c);

                        if (currProp) {
                            inputs.push(currProp);
                        }
                        else {
                            inputs.push({ propertyName: c });
                        }
                    });

                    setUserInputs(inputs);
                }
            }
            catch {
                console.log('error while parsing json of unit property specification');
            }
        }

        setInputs();
    }, [])



    /**
     * Handle navigation to the unit configuration editor
     *
     */
    const handleNavigation = () => {
        navigate('/admin/UnitConfigurationManagement/unitconfigurationeditor', { state: { unitConfiguration: unitConfiguration == undefined ? state.unitConfiguration : unitConfiguration } });
    };

    // Type guard function to check if a value is an empty string
    function isEmptyString(value: any | undefined): value is undefined {
        return value === undefined || (typeof value === 'string' && value.trim() === '');
    }

    
    /**
     * Handle the submit of the property range selection form 
     *
     * @return {*} 
     */
    const handleSubmit = async () => {
        setIsSaving(true);
        //find properties of unit config
        let propertyRanges = userInputs ?? [];
        //if the value is empty space set it to undefined
        for (let i = 0; i < propertyRanges.length; i++) {
            let current = propertyRanges[i];
            current.lessThan = isEmptyString(current.lessThan) ? undefined : Number(current.lessThan);
            current.greaterThan = isEmptyString(current.greaterThan) ? undefined : Number(current.greaterThan);
            current.isDecimal = current.isDecimal ?? true;
        }
        if (propertyRanges.length != 0) {

            //check if less than is less than greater than for all properties
            const incorrectEntries = propertyRanges.filter(x => x.lessThan != undefined && x.greaterThan != undefined && x.lessThan > x.greaterThan);
            const undefinedVals = propertyRanges.filter(x => (x.propertyName != PropertyConstants.TempApproachHotInlet &&
                x.propertyName != PropertyConstants.TempApproachHotOutlet &&
                x.propertyName != PropertyConstants.DeltaTemperatureColdSide &&
                x.propertyName != PropertyConstants.DeltaTemperatureHotSide))
                .filter(x => x.lessThan == undefined || x.greaterThan == undefined);

                //if there are any incorrect entries or undefined values, show error message and return
            if (incorrectEntries.length > 0 || undefinedVals.length > 0) {
                const incorrectPropertyNames = incorrectEntries.map(entry => entry.propertyName);
                const undefinedPropertyNames = undefinedVals.map(entry => entry.propertyName);
                let errorundefinedPropertyNames = "";
                if (undefinedPropertyNames.length > 0) {
                    errorundefinedPropertyNames = "Please fill in the values for properties: " + undefinedPropertyNames.join(', ') + ".";
                }

                undefinedVals.map(entry => entry.propertyName);
                const errorMessage = "Less than value should be less than greater than value for properties: " + incorrectPropertyNames.join(', ') + "\n" +
                    errorundefinedPropertyNames;

                setTemporaryState(errorMessage, setSaveMessage);
                setIsSaving(false);
                return;
            }

        }
 
        let payload: any = {
            ...unitConfig,
            properties: userInputs
        }

        let response = await apiService.putAsync(payload, `settings/propertyRanges`);

        if (!response.ok) {
            setIsSaving(false);
            setTemporaryState("Failed to save.", setSaveMessage);
            const message = await response.text();
            throw new Error(`${message} (${response.status})`);
        }

        const result: IUnitBaseConfiguration = await response.json();
        setStateUnitConfiguration({ ...unitConfiguration, properties: userInputs });
        setTemporaryState("✅ Saved", setSaveMessage);
        setIsSaving(false);
    }

    
    /**
     * Handle the change of the property range selection form 
     *
     * @param {string} property
     * @param {string} column
     * @param {*} e
     */
    const onChange = (property: string, column: string, e: any) => {
        setUserInputs(prevInputs => {
            const currentInputs = [...prevInputs!];

            const existingProperty = currentInputs.find((c: IPropertyRange) => c.propertyName === property);

            if (existingProperty) {
                if (column === Constants.LessThan) {
                    existingProperty.lessThan = Number(e.target.value);
                } else if (column === Constants.GreaterThan) {
                    existingProperty.greaterThan = Number(e.target.value);
                }
                else {
                    existingProperty.isDecimal = e.target.checked;
                }
            } else {
                let propRange: IPropertyRange = {
                    propertyName: property
                };
                if (column === Constants.LessThan) {
                    propRange.lessThan = Number(e.target.value);
                } else if (column === Constants.GreaterThan) {
                    propRange.greaterThan = Number(e.target.value);
                }
                else {
                    propRange.isDecimal = e.target.checked;
                }
                currentInputs.push(propRange);
            }
            return currentInputs;
        });
        resetMinMaxValuesForRow(property);
    };

    
    /**
     * Reset the min max values for a row in the property range selection form 
     *
     * @param {string} property
     */
    const resetMinMaxValuesForRow = (property: string) => {
        setUserInputs((userInputs) =>
            userInputs?.map((input) => {
                if (input?.greaterThan && input?.lessThan) {
                    if ((input?.propertyName === property && input.isDecimal === false) && (input?.greaterThan.toString()?.indexOf('.') > -1 && input?.lessThan.toString()?.indexOf('.') > -1)) {
                        return { ...input, lessThan: 0, greaterThan: 0 }
                    }
                    else if (input.propertyName === property && input.isDecimal === false && input?.lessThan!.toString()?.indexOf('.') > -1) {
                        return { ...input, lessThan: 0, greaterThan: input.greaterThan }
                    }
                    else if (input.propertyName === property && input.isDecimal === false && input?.greaterThan!.toString()?.indexOf('.') > -1) {
                        return { ...input, lessThan: input.lessThan, greaterThan: 0 }
                    }
                    else {
                        return input;
                    }
                }
                else {
                    return input;
                }
            })
        );
    };

    
    /**
     * Get the units for a property 
     *
     * @param {string} prop
     * @return {*} 
     */
    function getUnits(prop: string) {
        let unit = '';
        if (prop.toLowerCase().includes('flow')) {
            unit = getFlowTypeUnits(unitConfiguration?.flowType!)
        } else if (prop.toLowerCase().includes('temp')) {
            unit = temperatureUnit;
        }
        return unit;
    }

    return (<Stack>
        <Stack horizontal style={{ padding: 8 }} >
            <Stack.Item grow>
                <DefaultButton iconProps={{ iconName: "Back" }} text="Back" onClick={() => handleNavigation()} />
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal>
                    <Stack.Item style={{ marginTop: 5, marginRight: 8 }}>
                        {
                            isSaving && <Spinner labelPosition="left" label="Saving..." size={SpinnerSize.small} />
                        }
                        {
                            saveMessage &&
                            <span>{saveMessage}</span>
                        }
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton disabled={isSaving} iconProps={{ iconName: "Save" }} onClick={() => handleSubmit()} text="Save all information" />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
        <Stack styles={generalStackStyles(theme)} style={{ borderLeft: "5px solid " + theme.palette.themePrimary, marginTop: 20 }}>
            <Stack horizontal={false}>

                <Stack.Item grow>
                    <Label>Property Range selection</Label>
                </Stack.Item>
                <table width="80%">
                    <thead>
                        <tr>
                            <td width="30%" align="right" >
                                <label style={{ marginRight: "50px" }}>Property names</label>
                            </td>
                            <td width="20%">
                                <label>Minimum value</label>
                            </td>
                            <td width="20%">
                                <label>Maximum value</label>
                            </td>
                            <td  width="20%">
                                <label>Allow decimal value</label>
                            </td>
                        </tr>
                    </thead>
                </table>
                <hr></hr>
                <hr></hr>

                {userInputs && userInputs?.map((input, index) => (
                    input.propertyName && (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Label control */}
                            <table width="80%">
                                <tbody>
                                    <tr>
                                        <td width="30%" align="right" >
                                            <label style={{ marginRight: "50px" }}>{input.propertyName} ({getUnits(input.propertyName)}) </label>
                                        </td>
                                        <td width="20%">
                                            <TextField type='number'
                                                id={Constants.LessThan + index + input}
                                                value={(userInputs.find(x => x.propertyName == input.propertyName)?.lessThan) ? userInputs.find(x => x.propertyName == input.propertyName)?.lessThan!.toString() : ''}
                                                onChange={(e) => { onChange(input.propertyName, Constants.LessThan, e) }}></TextField>
                                        </td>
                                        <td width="20%">
                                            <TextField type='number'
                                                id={Constants.GreaterThan + index + input}
                                                value={(userInputs.find(x => x.propertyName == input.propertyName)?.greaterThan) ? userInputs.find(x => x.propertyName == input.propertyName)?.greaterThan!.toString() : ''}
                                                onChange={(e) => onChange(input.propertyName, Constants.GreaterThan, e)}
                                            /></td>
                                       <td width="20%" align="right" style={{ paddingLeft: "40px" }}>
                                            <Checkbox
                                                title="Allow decimal value for Minimum and Maximum value"
                                                label=""
                                                checked={input.isDecimal ?? true}
                                                onChange={(e) => onChange(input.propertyName, "allowDecimal", e)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    )
                ))}

            </Stack>

        </Stack>
    </Stack>
    )
}
