import React from 'react'
import '../../Tooltip/Tooltip-1/Tooltip1.scss';
import '../../Tooltip/Tooltip-4/Tooltip4.scss';
export default function Tooltip4() {
  return (
    <div className="tooltip1">
    <div className="emelie-tooltip emelie-design--tooltip-4">4</div>
    <div className="emelie-tooltip--property tooltiptext">
      <div className="tooltiptext--img">
      </div>
      <div className="tooltiptext--content">
      </div>
    </div>
  </div>
  )
}
