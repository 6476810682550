import React, { useEffect, useState } from "react";
import { DetailsList, Dropdown, IColumn, IDetailsListStyles, IDropdownOption, Label, SelectionMode, Spinner, SpinnerSize, Stack, useTheme } from "@fluentui/react";
import { IMsalContext, useMsal } from "@azure/msal-react";
import { ApiService } from "../../../Services/ApiService";
import { getFormattedDate } from '../../../Utils/UnitsHelper';
import { IDesignSession } from "../../../Models/IDesignSession";
import { generalStackStyles } from "../../../Models/StackStyling";

/**
 * History list component for displaying the history list 
 *
 * @return {*} 
 */
export const HistoryList: React.FC<{}> = () => {
  const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      overflow: "hidden",
    },
  };

  let theme = useTheme();

  const [pageSize, setPageSize] = useState(10);
  const [historyList, setHistoryList] = useState<IDesignSession[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const ctx: IMsalContext = useMsal();
  const dataService = new ApiService(ctx);

  // Columns for the history list
  const columns: IColumn[] = [
    {
      key: '1',
      name: 'Uploaded by',
      fieldName: 'user',
      minWidth: 300,
      maxWidth: 450,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      data: 'string',
      isPadded: true,
    },
    {
      key: '2',
      name: 'Created',
      fieldName: 'createdDate',
      minWidth: 200,
      maxWidth: 450,
      isRowHeader: true,
      isResizable: false,
      isSorted: true,
      isSortedDescending: true,
      data: 'string',
      isPadded: true,
      onRender: renderDate
    },
    {
      key: '3',
      name: 'Product Line',
      fieldName: 'productLine',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      data: 'string',
      isPadded: true,
    },
    {
      key: '4',
      name: 'Position',
      fieldName: 'alfaLavalPosition',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      data: 'string',
      isPadded: true,
    }
  ]

  const pageSizeOptions: IDropdownOption[] = [
    { key: 10, text: '10' },
    { key: 25, text: '25' },
    { key: 50, text: '50' },
    { key: 100, text: '100' }
  ]

  function renderDate(item: any, index?: number, column?: IColumn) {
    return <p>{getFormattedDate(item.createdDate)}</p>
  }


  useEffect(() => {
    setIsLoading(true);
    async function getHistoryData() {
      const response = await dataService.getAsync(`designSessions/user/history?take=${pageSize}`);
      const data = await response.json();

      const historyData: IDesignSession[] = data;

      setHistoryList(historyData);
      setIsLoading(false);
    }

    getHistoryData();
  }, [pageSize]);



  return (
    <Stack>
      <Stack horizontal>
        <Stack.Item style={{ paddingLeft: 20 }} grow>
          <h2>
            History
          </h2>
        </Stack.Item>
        <Stack.Item style={{ marginTop: 5, marginRight: 5 }}>
          <Label>Page size</Label>
        </Stack.Item>
        <Stack.Item style={{ marginRight: 20, marginTop: 5, width: 100 }}>
          <Dropdown
            options={pageSizeOptions}
            defaultSelectedKey={pageSize}
            onChange={(e, i) => setPageSize(i?.key as number)}
          />
        </Stack.Item>
      </Stack>
      <Stack styles={generalStackStyles(theme)}>
        <Stack.Item>
          {
            !isLoading ?

              <>
                {
                  historyList &&
                  <div>
                    <DetailsList
                      setKey='items'
                      items={historyList}
                      columns={columns}
                      selectionMode={SelectionMode.none}
                      styles={gridStyles}
                    />
                  </div>
                }
              </>
              :
              <Spinner className='loading-spinner' size={SpinnerSize.large} label={'Loading history...'} />
          }
        </Stack.Item>
      </Stack>
    </Stack>
  );
}
export default HistoryList;