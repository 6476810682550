import { ComboBox, Dropdown, IComboBoxOption, Label, Stack, TextField, getTheme } from "@fluentui/react";
import { IMultiSectionUnitConfiguration } from "../../../Models/IUnitConfiguration";
import { generalStackStyles } from "../../../Models/StackStyling";
import { PropertyConstants } from "../../../Models/Constants";


type MultiSectionUnitConfigurationProps = {
    section: IMultiSectionUnitConfiguration;
    index: number,
    displayIndex: number,
    inputOutputOptions?: IComboBoxOption[];
    onChange: (property: string, value: any, index: number) => void;
    onClick?: (index: number) => void;
}
const disableSlashKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const disabledKeys = ['/'];
    if (disabledKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
export const MultiSectionUnitConfiguration: React.FC<MultiSectionUnitConfigurationProps> = (props) => {
    const theme = getTheme();
    return (
        <Stack onClick={() => props.onClick && props.onClick(props.index)} styles={generalStackStyles(theme)} style={{ borderLeft: "5px solid " + theme.palette.themePrimary, marginTop: 20 }}>
            <Stack horizontal>
                <Stack.Item grow>
                    <Label>Section {props.section.sectionNumber}</Label>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal>
                        <Label>Calculation type</Label>
                        <Dropdown options={[{
                            key: "Performance",
                            text: "Performance"
                        },
                        {
                            key: "Rating",
                            text: "Rating"
                        }]}
                            selectedKey={props.section.calculationType ?? ''}
                            onChange={(e, item) => props.onChange(PropertyConstants.CalculationType, item!.key, props.index)}
                            style={{ width: 150, marginLeft: 10 }} />
                    </Stack>
                </Stack.Item>
            </Stack>
            <Stack horizontal>
                <Stack.Item grow>
                    <Label style={{ color: theme.palette.redDark }}>Hot media type</Label>
                    <TextField onFocus={() => props.onClick && props.onClick(props.index)} onKeyPress={disableSlashKey} value={props.section.hotMediaType ?? ""} onChange={(e) => props.onChange(PropertyConstants.HotMediaType, e.currentTarget.value, props.index)} />
                </Stack.Item>
                <Stack.Item style={{ marginLeft: 20 }} grow>
                    <Label style={{ color: theme.palette.themePrimary }}>Cold media type</Label>
                    <TextField onFocus={() => props.onClick && props.onClick(props.index)} onKeyPress={disableSlashKey} value={props.section.coldMediaType ?? ""} onChange={(e) => props.onChange(PropertyConstants.ColdMediaType, e.currentTarget.value, props.index)} />
                </Stack.Item>
            </Stack>
            <Label>Inputs</Label>
            <ComboBox
                onFocus={() => props.onClick && props.onClick(props.index)}
                multiSelect
                key={props.section.sectionNumber+"inputs"}
                options={props.inputOutputOptions?.map((x) => {
                    return { ...x, disabled: props.section.outputs?.includes(x.key.toString()) ?? false }
                }) ?? []}
                onChange={(e, i) => props.onChange(PropertyConstants.Inputs, i, props.index)}
                defaultSelectedKey={props.section.inputs ?? []}
            />
            <Label>Outputs</Label>
            <ComboBox
                onFocus={() => props.onClick && props.onClick(props.index)}
                multiSelect
                key={props.section.sectionNumber+"outputs"}
                options={props.inputOutputOptions?.map((x) => {
                    return { ...x, disabled: props.section.inputs?.includes(x.key.toString()) ?? false }
                }) ?? []}
                onChange={(e, i) => props.onChange(PropertyConstants.Outputs, i, props.index)}
                defaultSelectedKey={props.section.outputs ?? []}
            />
        </Stack>
    )
}
