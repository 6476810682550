export const AuthConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: "https://login.microsoftonline.com/ed5d5f47-52dd-48af-90ca-f7bd83624eb9", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};
const resource = process.env.REACT_APP_API_SCOPE?.replace('api://', '').replace('/.default', '')
export const Consent = {
  url: `https://login.microsoftonline.com/ed5d5f47-52dd-48af-90ca-f7bd83624eb9/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&r&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_mode=query&resource=${resource}&state=12345&response_type=id_token&prompt=consent`
}
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE as string]
};