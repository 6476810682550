import { IMsalContext } from "@azure/msal-react";
import { AlfaLavalPosition, Application, ColdMediaType, HotMediaType, Industry, ProcessStage, SubApplication, SubIndustry } from "../Models/Enums";
import { AuthenticationService } from "./AuthenticationService";
import { getPreferredUnits } from './Global';


/**
 * Design request interface
 *
 * @export
 * @interface IDesignRequest
 */
export interface IDesignRequest {
    industry: string;
    subIndustry: string;
    application: string;
    subApplication: string;
    alfaLavalPosition: string;

    hotMediaType: string;
    hotMediaFlowrate?: string;
    hotMediaPressureDrop?: string;
    hotMediaTempIn?: string;
    hotMediaTempOut?: string;

    coldMediaType: string;
    coldMediaFlowrate: string;
    coldMediaPressureDrop?: string;
    coldMediaTempIn?: string;
    coldMediaTempOut?: string;
}

/**
 * Design suggestion service for handling design suggestion requests 
 *
 * @export
 * @class DesignSuggestionService
 */
export class DesignSuggestionService {
    private context: IMsalContext;
    private apiUrl: string;

    constructor(ctx: IMsalContext) {
        this.context = ctx;
        this.apiUrl = process.env.REACT_APP_API_URL as string;
    }

    public async fetchDesignSuggestionFromApi(
        industry: Industry,
        subIndustry: SubIndustry,
        application: Application,
        subApplication: SubApplication,
        processStage: ProcessStage,
        alfaLavalPosition: AlfaLavalPosition,
        hotMediaType: HotMediaType,
        coldMediaType: ColdMediaType,
        hotMediaFlowrate?: string,
        hotMediaPressureDrop?: string,
        hotMediaTempIn?: string,
        hotMediaTempOut?: string,
        coldMediaFlowrate?: string,
        coldMediaPressureDrop?: string,
        coldMediaTempIn?: string,
        coldMediaTempOut?: string) {

        let payload: any = {
            industry: industry,
            subIndustry: subIndustry,
            application: application,
            subApplication: subApplication,
            processStage: processStage,
            alfaLavalPosition: alfaLavalPosition,
            hotMediaType: hotMediaType,
            coldMediaType: coldMediaType
        }

        payload = this.createPayload(payload, hotMediaFlowrate, hotMediaPressureDrop, hotMediaTempIn, hotMediaTempOut, coldMediaFlowrate, coldMediaPressureDrop, coldMediaTempIn, coldMediaTempOut);

        const authService = new AuthenticationService(this.context);

        const accessToken = await authService.getAccessToken();

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
                'units': getPreferredUnits().preferredUnits
            }
        }

        const url: string = `${this.apiUrl}/api/designsuggestion/positions`;
        const apiResponse = await fetch(url, requestOptions);

        return apiResponse;
    }

    
    /**
     * Update the design session in the API 
     *
     * @param {number} id
     * @param {Industry} industry
     * @param {SubIndustry} subIndustry
     * @param {Application} application
     * @param {SubApplication} subApplication
     * @param {ProcessStage} processStage
     * @param {AlfaLavalPosition} alfaLavalPosition
     * @param {HotMediaType} hotMediaType
     * @param {ColdMediaType} coldMediaType
     * @param {string} [hotMediaFlowrate]
     * @param {string} [hotMediaPressureDrop]
     * @param {string} [hotMediaTempIn]
     * @param {string} [hotMediaTempOut]
     * @param {string} [coldMediaFlowrate]
     * @param {string} [coldMediaPressureDrop]
     * @param {string} [coldMediaTempIn]
     * @param {string} [coldMediaTempOut]
     * @return {*} 
     * @memberof DesignSuggestionService
     */
    public async putDesignSessionToApi(
        id: number,
        industry: Industry,
        subIndustry: SubIndustry,
        application: Application,
        subApplication: SubApplication,
        processStage: ProcessStage,
        alfaLavalPosition: AlfaLavalPosition,
        hotMediaType: HotMediaType,
        coldMediaType: ColdMediaType,
        hotMediaFlowrate?: string,
        hotMediaPressureDrop?: string,
        hotMediaTempIn?: string,
        hotMediaTempOut?: string,
        coldMediaFlowrate?: string,
        coldMediaPressureDrop?: string,
        coldMediaTempIn?: string,
        coldMediaTempOut?: string
    ) {

        const authService = new AuthenticationService(this.context);
        const accessToken = await authService.getAccessToken();

        let payload: any = {
            industry: industry,
            subIndustry: subIndustry,
            application: application,
            subApplication: subApplication,
            processStage: processStage,
            alfaLavalPosition: alfaLavalPosition,
            hotMediaType: hotMediaType,
            coldMediaType: coldMediaType
        }

        payload = this.createPayload(payload, hotMediaFlowrate, hotMediaPressureDrop, hotMediaTempIn, hotMediaTempOut, coldMediaFlowrate, coldMediaPressureDrop, coldMediaTempIn, coldMediaTempOut);

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
                'units': getPreferredUnits().preferredUnits
            }
        }

        const url: string = `${this.apiUrl}/api/designsuggestion/positions/${id}`;
        const apiResponse = await fetch(url, requestOptions);

        return apiResponse;
    }

    
    /**
     * Get all design sessions from the API
     *
     * @param {number} offset
     * @param {number} pageSize
     * @return {*} 
     * @memberof DesignSuggestionService
     */
    public async getAllDesignSessionsFromApi(offset: number, pageSize: number) {
        const authService = new AuthenticationService(this.context);

        const accessToken = await authService.getAccessToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'units': getPreferredUnits().preferredUnits
            }
        }

        const url: string = `${this.apiUrl}/api/designsession?pageOffset=${offset}&pageSize=${pageSize}`;
        const apiResponse = await fetch(url, requestOptions);

        return apiResponse;
    }

    private createPayload(payload: any,
        hotMediaFlowrate?: string,
        hotMediaPressureDrop?: string,
        hotMediaTempIn?: string,
        hotMediaTempOut?: string,
        coldMediaFlowrate?: string,
        coldMediaPressureDrop?: string,
        coldMediaTempIn?: string,
        coldMediaTempOut?: string) {

        if (hotMediaFlowrate) {
            payload.hotMediaFlowrate = hotMediaFlowrate;
        }
        if (hotMediaPressureDrop) {
            payload.hotMediaPressureDrop = hotMediaPressureDrop;
        }
        if (hotMediaTempIn) {
            payload.hotMediaTempIn = hotMediaTempIn;
        }
        if (hotMediaTempOut) {
            payload.hotMediaTempOut = hotMediaTempOut;
        }
        if (coldMediaFlowrate) {
            payload.coldMediaFlowrate = coldMediaFlowrate;
        }
        if (coldMediaPressureDrop) {
            payload.coldMediaPressureDrop = coldMediaPressureDrop;
        }
        if (coldMediaTempIn) {
            payload.coldMediaTempIn = coldMediaTempIn;
        }
        if (coldMediaTempOut) {
            payload.coldMediaTempOut = coldMediaTempOut;
        }

        return payload;
    }

}