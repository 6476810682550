import { getTheme, Icon, IStackItemStyles, Stack } from '@fluentui/react';

/**
 * TileButton component
 *
 * @param {{ text: string, iconName: string, onClick: any, background?: string, fontColor?: string, file?: boolean }} props
 * @return {*} 
 */
export const TileButton: React.FC<{ text: string, iconName: string, onClick: any, background?: string, fontColor?: string, file?: boolean }> = (props) => {
    let theme = getTheme();
    const hoverableStackStyles: IStackItemStyles = {
        root: [
            {
                background: props.background !== undefined ? props.background : theme.palette.white,
                boxShadow: theme.effects.elevation4,
                color: props.fontColor !== undefined ? props.fontColor : theme.palette.black,
                padding: 15,
                margin: 15,
                borderRadius: 5,
                transition: '150ms',
                selectors: {
                    ':hover': {
                        boxShadow: theme.effects.elevation16,
                        transition: '50ms',
                        cursor: 'pointer'
                    }
                }
            }
        ]
    }

    return (
        <Stack onClick={props.onClick}>
            <Stack.Item styles={hoverableStackStyles} style={{ width: 250, height: 125 }}>
                <Stack horizontalAlign='center'>
                    <Icon iconName={props.iconName} style={{ fontSize: 50, padding: 15 }} />
                    <Stack horizontal style={{ marginTop: 5 }}>
                        <Stack.Item>
                            {props.text}
                            {
                                props.file &&
                                <input type='file' />
                            }
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}