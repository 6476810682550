import { Dropdown, Label, PrimaryButton, DefaultButton, Spinner, Stack, getTheme } from "@fluentui/react"
import "./MultiSectionVisualization.scss"
import * as d3 from "d3";
import { useEffect, useMemo, useState } from "react";
import { MultiSectionVisualization } from "./MultiSectionVisualization";
import { IPositionProps, IUnitFlowDefinition } from "../../../Models/IUnitFlowDefinition";
import { useMsal } from "@azure/msal-react";
import { ApiService } from "../../../Services/ApiService";
import { IUnitBaseConfiguration } from "../../../Models/IUnitConfiguration";
import { Badge } from "@fluentui/react-components";

interface PositionProps {
    x: number,
    y: number,
    isSelected?: boolean,
    step?: number,
    id: number
}

interface SectionPositionProps {
    id?: string,
    positionProperties: PositionProps[],
    propertyName: string,
    color?: string,
}

const defaultPositions: PositionProps[] = [
    { x: 10, y: 25, id: 0 }, // left top-outside-in
    { x: 40, y: -10, id: 1 }, // left top-in
    { x: 40, y: 25, id: 2 }, // left top
    { x: 10, y: 60, id: 3 }, // left outside-in
    { x: 40, y: 60, id: 4 }, // left bottom-outside-in
    { x: 40, y: 100, id: 5 }, // 
    { x: 190, y: -10, id: 6 },
    { x: 220, y: 25, id: 7 },
    { x: 190, y: 25, id: 8 },
    { x: 220, y: 60, id: 9 },
    { x: 190, y: 60, id: 10 },
    { x: 190, y: 100, id: 11 },
]

/**
 * MultiSectionFlowchart component
 *
 * @export
 * @param {{ sectionCount: number, selectableProperties?: string[], unitInputConfiguration: IUnitBaseConfiguration, scaling?: number, sectionMargin?: number, isEdit?: boolean, isNewCreated?: boolean }} props
 * @return {*} 
 */
export const MultiSectionFlowchart: React.FC<{
    sectionCount: number,
    selectableProperties?: string[],
    unitInputConfiguration: IUnitBaseConfiguration,
    scaling?: number,
    sectionMargin?: number,
    isEdit?: boolean,
    isNewCreated?: boolean
}> = (props) => {
    const ctx = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);
    let theme = getTheme();

    const [isLoading, setIsLoading] = useState(true);
    const [sections, setSections] = useState<any[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<string>('');
    const [secProps, setSecProps] = useState<Record<string, SectionPositionProps>>({});
    const [step, setStep] = useState<Record<string, number>>({});
    const [selectableProperties, setSelectableProperties] = useState<string[]>();
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const activeSection = secProps[selectedProperty];

    async function getExistingFlowchart() {
        //get the flow chart if the unit config is already in the database or if we're editing
        props.unitInputConfiguration.sectionCount = props.sectionCount;
        if (props.unitInputConfiguration !== undefined && (props.isEdit || props.isNewCreated)) {
            const configResponse = await apiService.getAsync('settings/unitconfiguration', props.unitInputConfiguration);
            const fullConfig = await configResponse.json();
            const response = await apiService.getAsync('settings/unitFlowDefinitions', fullConfig);
            if (!response.ok) {
                const error = await response.text();
                throw new Error('Could not get flowchart - ' + error);
            }

            const result: SectionPositionProps[] = await response.json();
            console.log('result', result);
            if (!result || result.length === 0) return;
            let allSectionPositions: Record<string, SectionPositionProps> = {};
            let selectableProps: string[] = props.selectableProperties ?? [];
            let currentSteps: Record<string, number> = {};
            for (let i = 0; i < result.length; i++) {
                const r = result[i];
                if (!selectableProperties?.includes(r.propertyName)) {
                    selectableProps.push(r.propertyName);
                }
                currentSteps[r.propertyName] = r.positionProperties.filter(x => x.isSelected).length;
                allSectionPositions[r.propertyName] = r;
            }


            setStep(currentSteps);
            setSecProps(allSectionPositions);
            console.log('new section positions', allSectionPositions)
            setSelectableProperties(selectableProps);
        }
    }

    
    /**
     * Select button
     *
     * @param {number} index
     * @param {string} [property]
     * @return {*} 
     */
    function selectButton(index: number, property?: string) {
        let currentSelections = activeSection.positionProperties;
        let currentStep = step[property!];
        if (!currentStep) currentStep = 0;
        let positionTarget = currentSelections.find(x => x.id === index);
        if (!positionTarget) return;
        if (positionTarget.isSelected) {
            positionTarget.isSelected = false;
            positionTarget.step = undefined;
            currentStep = currentStep - 1;
            setStep({ ...step, [property!]: currentStep });
        }
        else {
            positionTarget.isSelected = true;
            positionTarget.step = currentStep;
            setStep({ ...step, [property!]: currentStep + 1 });
        }

        currentSelections[index] = positionTarget;
        setSecProps({ ...secProps, [property!]: { ...secProps[property!], positionProperties: currentSelections } });
    }

    useEffect(() => {
        async function getExistingData() {
            await getExistingFlowchart();
        }
        getExistingData();
    }, [])

    useEffect(() => {
        setSelectableProperties(props.selectableProperties);
        if (props.selectableProperties === undefined) return;
        let allSectionPositions = secProps;

        // assertSectionsHaveAllPositions();
        setSecProps(allSectionPositions);

    }, [selectedProperty, props.selectableProperties])


    /**
     * Get default positions
     *
     * @return {*} 
     */
    function getDefaultPositions() {
        let allSectionPositions: PositionProps[] = []

        for (let i = 0; i < props.sectionCount; i++) {
            let startIndex = i === 0 ? i : 12 * i;
            for (let j = 0; j < 12; j++) {
                allSectionPositions.push({ ...defaultPositions[j], id: j + startIndex });
            }
        }

        return allSectionPositions;
    }

    useEffect(() => {
        if (!props.selectableProperties) return;
        let currentStep = step[selectedProperty];
        if (!currentStep) currentStep = 0;
        setStep({ ...step, [selectedProperty]: currentStep });

        const secs: any[] = [];
        for (let i = 0; i < props.sectionCount; i++) {
            secs.push(i);
        }

        setSections(secs);
        setIsLoading(false);
        if(props.unitInputConfiguration.sectionCount === undefined){
            props.unitInputConfiguration.sectionCount = props.sectionCount;
        }
    }, [selectedProperty, props.sectionCount])

    useEffect(() => {
        const svg = d3.select("svg")
            .attr("position", "absolute")
            .attr("width", 250 * (props.scaling ?? 1))
            .attr("height", 100)
            .classed("heat-exchanger-svg", true);

        drawSelectedPositions(Object.values(secProps), svg, 12, 12);
    }, [secProps])

    
    /**
     * Change selected property
     *
     * @param {string} property
     */
    function changeSelectedProperty(property: string) {
        setSelectedProperty(property);
        let currentStep = step[property];
        let activeSec = secProps[property];
        if (!activeSec) {
            activeSec = {
                propertyName: property,
                color: property.toLowerCase().includes('hot') ? theme.palette.redDark : theme.palette.themePrimary,
                positionProperties: getDefaultPositions()
            };
        }
        if (!currentStep) currentStep = 0;
        setStep({ ...step, [property]: currentStep });
        setSecProps({ ...secProps, [property]: activeSec });
    }

    
    /**
     * Draw selected positions
     *
     * @param {SectionPositionProps[]} sps
     * @param {*} svg
     * @param {number} [adjustmentX=0]
     * @param {number} [adjustmentY=0]
     */
    function drawSelectedPositions(sps: SectionPositionProps[], svg: any, adjustmentX: number = 0, adjustmentY: number = 0) {

        svg.selectAll("line").remove();

        sps = Object.values(secProps).filter(x => x !== undefined);

        for (let i = 0; i < sps.length; i++) {
            const target = sps[i];
            for (let j = 0; j < target.positionProperties.length; j++) {
                const positions = target.positionProperties.filter(x => x?.isSelected).sort((a, b) => a.step! - b.step!);
                if (!positions || positions.length === 0) continue;
                if (j + 1 === positions.length) break;
                let sectionAdjustment = Number.parseInt(target.propertyName.substring(target.propertyName.length - 1));

                // if we're editing, we should have space between the visualizations, otherwise, they should be kept together.
                let editModeFactor = props.scaling === 1 ? 250 : 200;
                sectionAdjustment = sectionAdjustment === props.sectionCount ? 0 : (props.sectionCount - sectionAdjustment) * editModeFactor * (props.scaling ?? 1);
                console.log('sectionadjustment', sectionAdjustment, props.scaling)

                svg
                    .append("line")
                    .attr("x1", positions[j].x * (props.scaling ?? 1) + sectionAdjustment)
                    .attr("y1", positions[j].y + adjustmentY)
                    .attr("x2", positions[j + 1].x * (props.scaling ?? 1) + sectionAdjustment)
                    .attr("y2", positions[j + 1].y + adjustmentY)
                    .attr("style", `stroke:${sps[i].color};stroke-width:2`)
            }
        }
    }

    
    /**
     * Handle save flows
     *
     */
    async function handleSaveFlows() {
        setIsSaving(true);
        let updatedCollection: SectionPositionProps[] = [];

        for (let i = 0; i < Object.keys(secProps).length; i++) {
            const key = Object.keys(secProps)[i];
            const payload: any = {
                ...props.unitInputConfiguration,
                propertyName: key,
                color: secProps[key].color!,
                positionProperties: secProps[key].positionProperties,
                id: secProps[key].id
            }

            let response: any;
            if (payload.id) {
                response = await apiService.putAsync(payload, `settings/unitFlowDefinitions/${payload.id}`);
            }
            else {
                response = await apiService.postAsync(payload, 'settings/unitFlowDefinitions', props.unitInputConfiguration);
            }
            if (!response.ok) {
                const error = await response.text();
                throw new Error('Could not save flowchart - ' + error);
            }

            const result = await response.json();
            updatedCollection.push(result);
        }

        await getExistingFlowchart();
        setIsSaving(false);
    }

    
    /**
     * Handle is editing
     *
     */
    function handleIsEditing() {
        const isEdit = isEditing
        if (!isEdit) {
            let selected = secProps[Object.keys(secProps)[0]] ?? selectableProperties![0];
            setSelectedProperty(selected.propertyName);
        }

        setIsEditing(!isEdit);
    }

    return (
        <Stack>
            <Stack style={{ marginBottom: 120, marginTop: 20 }}>
                {
                    isLoading ?
                        <Spinner label="Loading..." ariaLive="assertive" labelPosition="right" />
                        :

                        <>
                            <Stack>
                                <Stack horizontal style={{ position: 'relative' }}>
                                    {
                                        sections &&
                                        sections.map((section, index) => {
                                            let startIndex = index === 0 ? index : 12 * index;

                                            return <Stack.Item style={{ width: props.scaling === 1 ? 250 * (props.scaling ?? 1) : 200 * (props.scaling ?? 1) }}>

                                                <div style={{ position: 'relative' }}>
                                                    <div>
                                                        {
                                                            isEditing &&
                                                            activeSection &&
                                                            activeSection?.positionProperties.slice(startIndex, section === 0 ? 12 : startIndex + 12).map((pp, pindex) => {
                                                                let currentSelection = pp.step === step[selectedProperty] - 1;
                                                                return <span
                                                                    onClick={() => { selectButton(pp.id!, selectedProperty) }}
                                                                    key={`section-button-${pp.id}`}
                                                                    id={`s-button-${pp.id}`}
                                                                    style={{
                                                                        boxSizing: 'border-box',
                                                                        marginLeft: pp.x * (props.scaling ?? 1),
                                                                        marginTop: pp.y,
                                                                        color: pp.isSelected ? 'white' : 'black',
                                                                        border: `solid 1px ${theme.palette.themeSecondary}`,
                                                                        background: pp.isSelected ? currentSelection ? 'orange' : activeSection.color : theme.palette.neutralLight,
                                                                        zIndex: 1000,
                                                                    }} className={`clickable-flow-button ${currentSelection && 'animate-current-step'}`}>
                                                                    {/* {isSelected ? posProps.step! : ''} */}
                                                                    {pp.isSelected ? pp.step : ''}
                                                                </span>
                                                            })
                                                        }
                                                    </div>
                                                    <div style={{ position: 'absolute' }}>
                                                        <MultiSectionVisualization
                                                            scaling={props.scaling}
                                                            leftEnding={index === 0} rightEnding={index === sections.length - 1}
                                                            label={`${sections.length - index}`} />
                                                    </div>
                                                </div>
                                            </Stack.Item>
                                        })
                                    }
                                </Stack>
                            </Stack>
                        </>
                }

                <svg style={{ marginLeft: 12 * (props.scaling ?? 1), position: "absolute", height: 200, width: props.sectionCount * 250 * (props.scaling ?? 1), zIndex: 900, marginTop: 0 }} />
            </Stack>
            {
                props.scaling === 1 &&
                <Stack.Item style={{ marginBottom: 20, marginTop: 140, zIndex: 1000 }}>
                    <DefaultButton text={isEditing ? "Hide editing nodes" : "Edit flowchart"} onClick={() => handleIsEditing()} iconProps={{ iconName: isEditing ? "Hide" : "Edit" }} />

                    {
                        selectableProperties && isEditing &&
                        <>
                            <Label>Input or output to map</Label>
                            <Dropdown
                                options={selectableProperties?.map((prop) => { return { key: prop, text: prop } }) ?? []}
                                onChange={(e, option) => changeSelectedProperty(option!.key.toString())}
                                selectedKey={[selectedProperty]} />
                            <Stack>
                                <Stack.Item>
                                    <Stack>
                                        <Stack.Item>
                                            <Label>Mapped properties:</Label>
                                        </Stack.Item>
                                        <Stack horizontal wrap style={{ maxWidth: 700 }} tokens={{ childrenGap: 10 }}>

                                            {
                                                secProps &&
                                                Object.keys(secProps).map((key) => {
                                                    if (key === undefined || secProps[key] === undefined || secProps[key].positionProperties.filter(x => x.isSelected).length === 0) return;
                                                    return <Stack.Item>
                                                        <Badge onClick={() => changeSelectedProperty(key)} appearance={selectedProperty === key ? "filled" : "tint"} color={key.toLowerCase().includes('hot') ? "danger" : "brand"} style={{ padding: 15, marginLeft: 5 }}>{key}</Badge>
                                                    </Stack.Item>
                                                })
                                            }
                                        </Stack>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item style={{ marginTop: 20 }}>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <PrimaryButton disabled={isSaving} text="Save flowchart" onClick={() => handleSaveFlows()} iconProps={{ iconName: "Save" }} />
                                        {
                                            isSaving &&
                                            <Spinner label="Saving..." ariaLive="assertive" labelPosition="right" />
                                        }
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </>
                    }
                </Stack.Item>
            }

        </Stack>
    )
}