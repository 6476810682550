import { ActionButton, DefaultButton, IconButton, Label, PrimaryButton, Spinner, Stack, TooltipHost, useTheme } from "@fluentui/react"
import { BlindTestList } from "./BlindTestList"
import { createRef, useMemo, useState } from "react";
import { IMsalContext, useMsal } from "@azure/msal-react";
import { ApiService } from "../../../Services/ApiService";
import { PositionSelection } from "../../Atom/PositionSelection/PositionSelection";
import { IUnitConfiguration } from "../../../Models/IUnitConfiguration";
import { getUnitConfigurationFromConfigurationKey } from "../../../Utils/UnitConfigurationHelper";
import saveAs from "file-saver";
import { IBlindTest } from "../../../Models/IBlindTest";

/**
 * Blind test overview component for displaying the blind tests 
 *
 * @return {*} 
 */
export const BlindTestOverview: React.FC<{}> = () => {
    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);
    let theme = useTheme();

    const [downloading, setDownloading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [unitConfiguration, setUnitConfiguration] = useState<IUnitConfiguration>();
    const [blindTests, setBlindTests] = useState<IBlindTest[]>([]);
    const [csvFile, setCsvFile] = useState<File>();
    const [showUpload, setShowUpload] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('Select csv file...');

    const handleChange = (event: any) => {
        const c = getUnitConfigurationFromConfigurationKey(event);
        getBlindTestsAsync(c);
        setUnitConfiguration(c);
    }

    
    /**
     * Get blind tests from the API 
     *
     * @param {IUnitConfiguration} config
     */
    async function getBlindTestsAsync(config: IUnitConfiguration) {
        setIsLoading(true);
        const response = await apiService.getAsync(`blindtests`, config);
        if (!response.ok) {
            setIsLoading(false);
            throw new Error('Could not fetch blind tests');
        }
        const result = await response.json();
        setBlindTests(result);
        setIsLoading(false);
    }

    
    /**
     * Handle file change event 
     *
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
        const uploadedFiles: FileList = e.currentTarget.files!
        const filesToAdd: File[] = []
        Array.from(uploadedFiles).map((file) => {
            filesToAdd.push(file)
        })

        let f = filesToAdd[0];
        setFileName(f.name);


        setCsvFile(f);
    }

    
    /**
     * Submit csv file to the API 
     *
     * @return {*} 
     */
    const submitCsv = async () => {
        setUploading(true);
        if (csvFile === undefined) return;

        let formData: FormData = new FormData();
        formData.append("file", csvFile, csvFile.name);

        const response = await apiService.postAsync(formData, 'blindtests/csv', unitConfiguration);
        if (!response.ok) {
            setUploading(false);
            throw new Error('Could not upload csv file. ' + await response.text());
        }

        const blindTestResponse = await apiService.getAsync(`blindtests`, unitConfiguration);
        if (!blindTestResponse.ok) {
            setUploading(false);
            throw new Error('Could not fetch blind tests');
        }
        const result = await blindTestResponse.json();
        setBlindTests(result);
        clearCsv();
        setUploading(false);
    }

    
    /**
     * Convert stream to csv
     *
     * @param {ReadableStream} stream
     * @param {string} filename
     */
    async function convertStreamToCsv(stream: ReadableStream, filename: string) {
        const reader = stream.getReader();
        let csvData = '';
        while (true) {
            const result = await reader.read();
            if (result.done) {
                break;
            }
            csvData += new TextDecoder().decode(result.value);
        }
        const blob = new Blob([csvData], { type: 'text/csv' });
        saveAs(blob, filename);
        setDownloading(false);
    }

    
    /**
     * Generate csv file
     *
     * @return {*} 
     */
    async function generateCSV() {
        if (unitConfiguration === undefined) return;

        setDownloading(true);
        const response = await apiService.getAsync('blindtests/generatecsv', unitConfiguration);
        if (!response.ok) {
            setDownloading(false);
            throw new Error('Could not fetch csv sample. ' + response.statusText);
        }
        convertStreamToCsv(response.body!, unitConfiguration.processStage + unitConfiguration.alfaLavalPosition + ".csv");
    }


    const inputFile = createRef<HTMLInputElement>();
    const uploadCsv = () => {
        inputFile.current?.click();
    }

    
    /**
     * Clear csv file 
     *
     */
    const clearCsv = () => {
        setCsvFile(undefined);
        setFileName('Select csv file...');
        console.log(inputFile.current);
        if (inputFile.current !== null && inputFile.current !== undefined) {
            inputFile.current!.value = '';
        }
    }

    return (
        <Stack>
            <Stack horizontal>
                <Stack.Item grow>
                    <h3 style={{ padding: 10 }}>Blind tests</h3>
                </Stack.Item>
                <Stack.Item style={{ marginRight: 20, marginTop: 8 }}>
                    {
                        unitConfiguration?.alfaLavalPosition &&
                        <Stack horizontal>
                            {
                                (downloading || uploading) &&
                                <Stack.Item>
                                    <Spinner style={{ padding: 5 }} label={downloading?"Downloading":"Uploading"} labelPosition="right"/>
                                </Stack.Item>
                            }
                            <Stack.Item>
                                <input ref={inputFile} type='file' hidden accept='.csv' onChange={handleFileChange} />
                                {
                                    fileName !== 'Select csv file...' &&
                                    <TooltipHost content='Remove file'>
                                        <IconButton style={{ color: theme.palette.red }} iconProps={{ iconName: 'Cancel' }} onClick={() => clearCsv()} />
                                    </TooltipHost>
                                }
                                <ActionButton iconProps={{ iconName: 'FileCode' }} text={fileName} onClick={() => uploadCsv()} disabled={downloading} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 10 }}>
                                <DefaultButton iconProps={{ iconName: 'Upload' }} text='Upload blind test data' onClick={() => submitCsv()} disabled={uploading || isLoading || csvFile === undefined || downloading} />
                            </Stack.Item>
                            <Stack.Item style={{ marginLeft: 10 }}>
                                <PrimaryButton iconProps={{ iconName: 'Download' }} disabled={downloading} text='Download csv template' onClick={() => { generateCSV() }}></PrimaryButton>
                            </Stack.Item>
                        </Stack>
                    }
                </Stack.Item>
            </Stack>

            <PositionSelection onChange={handleChange} />

            {
                unitConfiguration &&
                <BlindTestList unitConfiguration={unitConfiguration} blindTests={blindTests} />
            }
        </Stack>
    )
}