import { IMsalContext, useMsal } from '@azure/msal-react';
import { CheckboxVisibility, DetailsList, DetailsRow, Dropdown, FontWeights, IColumn, IconButton, IDetailsListStyles, IDropdownOption, Label, mergeStyleSets, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, TooltipHost, useTheme } from '@fluentui/react';
import { useEffect, useMemo, useState } from 'react';
import { hasRole } from '../../../Services/Global';
import { ApiService } from '../../../Services/ApiService';
import { ISMEDesignguidelines } from '../../../Models/ISMEDesignGuidelines';
import { Roles } from '../../../Models/Enums';
import { IUnitConfiguration } from '../../../Models/IUnitConfiguration';
import { getFormattedDate } from '../../../Utils/UnitsHelper';

/**
 * SMEDesignGuideline component for managing the SME design guidelines 
 *
 * @return {*} 
 */
export const SMEDesignGuideline: React.FC<{}> = () => {
    const ctx: IMsalContext = useMsal();
    let theme = useTheme();

    const [isModalOpen, setIsModalOpen] = useState<boolean>();
    const [currentData, setCurrentData] = useState<ISMEDesignguidelines[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [title, setTitle] = useState('');
    const [isDisbled, setDisabledControls] = useState<boolean>(false);
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const [industries, setIndustries] = useState<IDropdownOption<any>[]>();
    const [subIndustries, setSubIndustries] = useState<IDropdownOption<any>[]>();
    const [applications, setApplications] = useState<IDropdownOption<any>[]>();
    const [subApplications, setSubApplications] = useState<IDropdownOption<any>[]>();
    const [productLines, setProductLines] = useState<IDropdownOption<any>[]>();
    const [processStages, setProcessStages] = useState<IDropdownOption<any>[]>([]);
    const [alfaLavalPositions, setAlfaLavalPositions] = useState<IDropdownOption<any>[]>([]);
    const [designSuggestion, setDesignSuggestion] = useState('');

    const [selectedPosition, setselectedPosition] = useState<string>('');
    const [selectedIndustry, setSelectedIndustry] = useState<string>('');
    const [selectedSubIndustry, setSelectedSubIndustry] = useState<string>('');
    const [selectedApplication, setSelectedApplication] = useState<string>('');
    const [selectedSubApplication, setSelectedSubApplication] = useState<string>('');
    const [selectedProcessStage, setSelectedProcessStage] = useState<string>('');
    const [selectedProductLine, setSelectedProductLine] = useState<string>('');

    const contentStyles = modalStyles(theme);

    
    /**
     * Modal styles 
     *
     * @param {*} theme
     * @return {*} 
     */
    function modalStyles(theme: any) {
        return mergeStyleSets({
            container: {
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'stretch',
            },
            header: [
                theme.fonts.xLargePlus,
                {
                    flex: '1 1 auto',
                    flexDirection: 'row',
                    borderTop: `4px solid ${theme.palette.themePrimary}`,
                    color: theme.palette.neutralPrimary,
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: FontWeights.bold,
                    padding: '12px 12px 14px 24px',
                    backgroundColor: 'lightgray',
                    flexdirection: 'row'
                },
            ],
            body: {
                display: 'table',
                flex: '4 4 auto',
                borderSpacing: 4,
                padding: '0 24px 24px 24px',
                margin: '4px',
                overflowY: 'hidden',
                selectors: {
                    p: { margin: '14px 0' },
                    'p:first-child': { marginTop: 0 },
                    'p:last-child': { marginBottom: 0 },
                },
            }
        });
    }
    // Grid styles
    const gridStyles: Partial<IDetailsListStyles> = {
        root: {
            overflowX: 'auto',
            borderRadius: 5,
            marginTop: 20,
            width: '100%',
            boxShadow: theme.effects.elevation4
        }
    }
    const iconButtonStyles = iconModalStyles(theme);
    function iconModalStyles(theme: any) {
        return {
            root: {
                color: theme.palette.neutralPrimary,
                marginLeft: 'auto',
                marginTop: '4px',
                marginRight: '2px',
            },
            rootHovered: {
                color: theme.palette.neutralDark,
            },
        };
    }

    
    /**
     * Show modal 
     *
     * @param {ISMEDesignguidelines} [item]
     */
    function showModal(item?: ISMEDesignguidelines) {

        setselectedPosition(item?.alfaLavalPosition!);
        setSelectedIndustry(item?.industry!);
        setSelectedApplication(item?.application!);
        setSelectedSubApplication(item?.subApplication!);
        setDesignSuggestion(item?.designSuggestion!);
        setSelectedProcessStage(item?.processStage!);
        setSelectedProductLine(item?.productLine!);
        setSelectedSubIndustry(item?.subIndustry!);
        setTitle(item == undefined ? "New Design guideline" : "Edit Design guideline");
        setDisabledControls(item != undefined);
        setIsModalOpen(true);
    }

    function hideModal() {
        setIsModalOpen(false);
    }

    // Table columns
    const tableColumns: IColumn[] = [
        {
            key: 'industry',
            minWidth: 100,
            maxWidth: 150,
            isMultiline: true,
            name: 'Industry',
            fieldName: 'industry',
            onRender: (item?: ISMEDesignguidelines, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.industry}>
                        <p>
                            {item?.industry}
                        </p>
                    </TooltipHost>
                )
            }
        },

        {
            key: 'subIndustry',
            minWidth: 100,
            maxWidth: 150,
            isMultiline: true,
            name: 'Sub Industry',
            fieldName: 'subIndustry',
            onRender: (item?: ISMEDesignguidelines, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.subIndustry}>
                        <p>
                            {item?.subIndustry}
                        </p>
                    </TooltipHost>
                )
            }
        },

        {
            key: 'application',
            minWidth: 100,
            maxWidth: 150,
            name: 'Application',
            isMultiline: true,
            fieldName: 'application',
            onRender: (item?: ISMEDesignguidelines, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.application}>
                        <p>
                            {item?.application}
                        </p>
                    </TooltipHost>
                )
            }
        },

        {
            key: 'subApplication',
            minWidth: 100,
            maxWidth: 200,
            name: 'Sub Application',
            isMultiline: true,
            fieldName: 'subApplication',
            onRender: (item?: ISMEDesignguidelines, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.subApplication}>
                        <p>
                            {item?.subApplication}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'processStage',
            minWidth: 70,
            maxWidth: 120,
            name: 'Process Stage',
            fieldName: 'processStage',
            onRender: (item?: ISMEDesignguidelines, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.processStage}>
                        <p>
                            {item?.processStage}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'productLine',
            minWidth: 100,
            maxWidth: 100,
            name: 'Product Line',
            fieldName: 'productLine',
            onRender: (item?: ISMEDesignguidelines, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.productLine}>
                        <p>
                            {item?.productLine}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'alfaLavalPosition',
            minWidth: 150,
            maxWidth: 150,
            name: 'Alfa Laval Position',
            fieldName: 'alfaLavalPosition',
            onRender: (item?: ISMEDesignguidelines, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.alfaLavalPosition}>
                        <p>
                            {item?.alfaLavalPosition}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'designSuggestion',
            minWidth: 150,
            maxWidth: 150,
            name: 'Design suggestion',
            fieldName: 'designSuggestion',
            onRender: (item?: ISMEDesignguidelines, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.designSuggestion}>
                        <p>
                            {item?.designSuggestion}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'created',
            minWidth: 100,
            maxWidth: 100,
            name: 'Created',
            fieldName: 'created',
            onRender: (item?: any, index?: number, column?: IColumn) => {
                if (item.created != null || item.created != undefined) {
                    const dateValid = getFormattedDate(item.created);
                    return (
                        <TooltipHost content={<p>{dateValid}</p>}>
                            <p>
                                {dateValid}
                            </p>
                        </TooltipHost>
                    )
                }
            }
        }, {
            key: 'version',
            minWidth: 100,
            maxWidth: 100,
            name: 'Version',
            fieldName: 'version',
            onRender: (item?: any, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={<p>Version: {item.version}</p>}>
                        <p>
                            {item.version}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'none',
            minWidth: 50,
            maxWidth: 50,
            name: '',
            onRender: (item?: ISMEDesignguidelines, index?: number, column?: IColumn) => {
                if (item?.isEditable && item.canEdit) {
                    return (
                        <>
                            {
                                <IconButton style={{ color: theme.palette.themePrimary }} iconProps={{ iconName: 'Edit' }} onClick={() => showModal(item!)} />
                            }
                        </>
                    )
                }
                else {
                    return (

                        <>
                            <TooltipHost content={'This guideline is used in active blind test or data sample, cannot be edited'}>
                                <IconButton style={{ color: theme.palette.themePrimary }} iconProps={{ iconName: 'Edit' }} disabled onClick={() => showModal(item!)} >
                                </IconButton>
                            </TooltipHost>

                        </>
                    )
                }
            }
        }
    ]

    useEffect(() => {

        // Get all unit configurations from the API
        const getAllUnitConfigs = async () => {
            const response = await apiService.getAsync('settings/unitconfigurations');
            const data = await response.json();

            const unitConfigData: IUnitConfiguration[] = data;
            console.log('unitConfigData', unitConfigData)

            // Get unique values for each dropdown option industry, subindustry, application, subapplication, productline, processstage, alfalavalposition
            const industries: any[] = Array.from(new Set(unitConfigData.map((item: IUnitConfiguration) => item.industry)));
            let inds: IDropdownOption[] = [];
            industries.forEach((ind: any) => {
                inds?.push({
                    key: ind,
                    text: ind
                })
            });
            setIndustries(inds);

            const subIndustries: any[] = Array.from(new Set(unitConfigData.map((item: IUnitConfiguration) => item.subIndustry)));
            let subInds: IDropdownOption[] = [];
            subIndustries.forEach((ind: any) => {
                subInds?.push({
                    key: ind,
                    text: ind
                })
            });
            setSubIndustries(subInds);

            const applications: any[] = Array.from(new Set(unitConfigData.map((item: IUnitConfiguration) => item.application)));
            let application: IDropdownOption[] = [];
            applications.forEach((app: any) => {
                application?.push({
                    key: app,
                    text: app
                })
            });
            setApplications(application);

            const subapplications: any[] = Array.from(new Set(unitConfigData.map((item: IUnitConfiguration) => item.subApplication)));
            let subapplication: IDropdownOption[] = [];
            subapplications.forEach((subapp: any) => {
                subapplication?.push({
                    key: subapp,
                    text: subapp
                })
            });
            setSubApplications(subapplication);

            const prodLines: any[] = Array.from(new Set(unitConfigData.map((item: IUnitConfiguration) => item.productLine)));
            let prodLine: IDropdownOption[] = [];
            prodLines.forEach((prod: any) => {
                prodLine?.push({
                    key: prod,
                    text: prod
                })
            });
            setProductLines(prodLine);

            const processStages: any[] = Array.from(new Set(unitConfigData.map((item: IUnitConfiguration) => item.processStage)));
            let processStage: IDropdownOption[] = [];
            processStages.forEach((proc: any) => {
                processStage?.push({
                    key: proc,
                    text: proc
                })
            });
            setProcessStages(processStage);

            const positions = Array.from(new Set(unitConfigData.map((item: IUnitConfiguration) => item.alfaLavalPosition)));
            let position: IDropdownOption[] = [];
            positions.forEach((pos: any) => {
                position?.push({
                    key: pos,
                    text: pos
                })
            });

            setAlfaLavalPositions(position)

        }
        getAllDesignGuidelines();
        getAllUnitConfigs();

    }, []);

    
    /**
     * Get all design guidelines from the API
     *
     */
    const getAllDesignGuidelines = async () => {
        setIsLoading(true);
        const response = await apiService.getAsync('settings/smedesignguidelines/latest/all');
        if (!response.ok) {
            const res = await response.text();
            setIsLoading(false);
            throw Error(`Could not load sme design guidelines. ${response.status} ${res}`);
        }

        const data = await response.json();
        const sessionData: [] = data;
        setCurrentData(sessionData);
        setIsLoading(false);
    }

    
    /**
     * Add new design guideline 
     *
     */
    const addNewDesignGuideLine = async () => {
        let body: any = {}
        setIsLoading(true);
        body.industry = selectedIndustry;
        body.subIndustry = selectedSubIndustry;
        body.application = selectedApplication;
        body.subApplication = selectedSubApplication;
        body.alfaLavalPosition = selectedPosition;
        body.processStage = selectedProcessStage;
        body.productLine = selectedProductLine;
        body.designSuggestion = designSuggestion;

        hideModal();
        const response = await apiService.postAsync(body, `settings/smedesignguidelines`);

        if (!response.ok) {
            const res = await response.text();
            setIsLoading(false);
            throw Error(`Could not send response. ${response.status} ${res}`);
        }
        getAllDesignGuidelines();
    }

    
    /**
     * Handle change event for dropdowns industry, subindustry, application, subapplication, productline, position, processstage
     *
     * @param {IDropdownOption<any>} [option]
     * @param {string} [name]
     * @return {*} 
     */
    const handleChange = (option?: IDropdownOption<any>, name?: string) => {
        if (option === undefined) return;
        switch (name) {
            case 'industry':
                setSelectedIndustry(option.key as string);
                break;
            case 'subIndustry':
                setSelectedSubIndustry(option.key as string);
                break;
            case 'application':
                setSelectedApplication(option.key as string);
                break;
            case 'subApplication':
                setSelectedSubApplication(option.key as string);
                break;
            case 'prodLine':
                setSelectedProductLine(option.key as string);
                break;
            case 'position':
                setselectedPosition(option.key as string);
                break;
            case 'processStage':
                setSelectedProcessStage(option.key as string);
                break;
            default:
                break;
        }
    }

    
    /**
     * On render row for the details list 
     *
     * @param {*} props
     * @param {*} [defaultRender]
     * @return {*}  {JSX.Element}
     */
    const onRenderRow = (props: any, defaultRender?: any): JSX.Element => {
        if (props.item.canEdit === false) {
            const customStyles = {
                backgroundColor: '#fffec8',
                fontWeight: 'bold'
            };

            return <><TooltipHost content={'This guideline is used in active blind test or data sample, cannot be edited'}> <DetailsRow {...props} className={customStyles} /></TooltipHost> </>
        }
        else {
            return <DetailsRow {...props} />;
        }
    };

    return (
        <Stack horizontal>
            <Stack.Item style={{ padding: '10px' }}>
                <PrimaryButton
                    text='Create new design guideline'
                    iconProps={{ iconName: 'Add' }}
                    onClick={() => { showModal() }}
                    disabled={isLoading} />
                <Modal titleAriaId="id" isOpen={isModalOpen === true} isModeless={false} onDismiss={hideModal} isBlocking={true} isDarkOverlay={true}
                    containerClassName={contentStyles.container} >
                    <Stack grow className={contentStyles.header}>
                        <Label>{title}</Label>
                        <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }} ariaLabel="Close"
                            style={{ float: 'right' }} onClick={hideModal} />
                    </Stack>
                    <form onSubmit={(e) => { e.preventDefault(); return addNewDesignGuideLine() }}>
                        <table className={contentStyles.body}>
                            <tbody>
                                <tr>
                                    <td>
                                        <Label>Industry</Label>
                                    </td>
                                    <td style={{ minWidth: 250 }}>
                                        <Dropdown disabled={isDisbled} options={industries!} style={{ height: '30px', fontSize: '14' }} onChange={(e, item) => handleChange(item, 'industry')} selectedKey={selectedIndustry} />
                                    </td>
                                    <td style={{ paddingLeft: '30px' }}>
                                        <Label>Subindustry</Label>
                                    </td>
                                    <td style={{ minWidth: 250 }}>
                                        <Dropdown disabled={isDisbled} options={subIndustries!} style={{ height: '30px', fontSize: '14' }} onChange={(e, item) => handleChange(item, 'subIndustry')} selectedKey={selectedSubIndustry} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label>Application</Label>
                                    </td>
                                    <td>
                                        <Dropdown disabled={isDisbled} options={applications!} style={{ height: '30px', fontSize: '14' }} onChange={(e, item) => handleChange(item, 'application')} selectedKey={selectedApplication} />
                                    </td>
                                    <td style={{ paddingLeft: '30px' }}>
                                        <Label>Subapplication</Label>
                                    </td>
                                    <td>
                                        <Dropdown disabled={isDisbled} options={subApplications!} style={{ height: '30px', fontSize: '14' }} onChange={(e, item) => handleChange(item, 'subApplication')} selectedKey={selectedSubApplication} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label>Process stage</Label>
                                    </td>
                                    <td>
                                        <Dropdown disabled={isDisbled} options={processStages!} style={{ height: '30px', fontSize: '14' }} onChange={(e, item) => handleChange(item, 'processStage')} selectedKey={selectedProcessStage} />
                                    </td>
                                    <td style={{ paddingLeft: '30px' }}>
                                        <Label>Product line</Label>
                                    </td>
                                    <td>
                                        <Dropdown disabled={isDisbled} options={productLines!} style={{ height: '30px', fontSize: '14' }} onChange={(e, item) => handleChange(item, 'prodLine')} selectedKey={selectedProductLine} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label>Alfa laval position</Label>
                                    </td>
                                    <td>
                                        <Dropdown disabled={isDisbled} options={alfaLavalPositions!} style={{ height: '30px', fontSize: '14' }} onChange={(e, item) => handleChange(item, 'position')} selectedKey={selectedPosition} />
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <Label>Design suggestion</Label>
                                    </td>
                                    <td colSpan={2}>
                                        <TextField style={{ height: '50px', fontSize: '14' }} onChange={(e, i) => setDesignSuggestion(i!.toString())} multiline rows={3} value={designSuggestion}></TextField>
                                    </td>

                                </tr>

                            </tbody>
                        </table>
                        <div style={{ textAlign: 'center', marginBottom: 10 }}>
                            <PrimaryButton type='submit' text='Submit' iconProps={{ iconName: 'CheckMark' }} />
                        </div>
                    </form>

                </Modal>
                {
                    isLoading === true ?
                        <Spinner className='loading-spinner' size={SpinnerSize.large} label={'Loading...'} />
                        :
                        <>
                            <DetailsList items={currentData ?? []}
                                styles={gridStyles}
                                columns={tableColumns}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                onRenderRow={onRenderRow} />
                        </>
                }
            </Stack.Item>
        </Stack>

    );
}
