import React from 'react';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { ActionButton, DefaultButton, IconButton, Label, PrimaryButton, Spinner, SpinnerSize, Stack, useTheme } from '@fluentui/react'
import { createRef, useEffect, useMemo, useState } from 'react';
import { IDataSampleSummaryItem } from '../../../Models/IDataSampleSummaryItem';
import { ApiService } from '../../../Services/ApiService';
import { TileButton } from '../../Atom/TileButton/TileButton';
import { hasRole } from "../../../Services/Global";
import { IUnitConfiguration } from '../../../Models/IUnitConfiguration';
import { saveAs } from 'file-saver';
import { DataSampleList } from './DataSampleList';
import { Roles } from '../../../Models/Enums';
import { PositionSelection } from '../../Atom/PositionSelection/PositionSelection';
import { getUnitConfigurationFromConfigurationKey } from '../../../Utils/UnitConfigurationHelper';
import { generalStackStyles } from '../../../Models/StackStyling';

/**
 * Modeler overview component to display the modeler overview page
 *
 * @return {*} 
 */
export const ModelerOverview: React.FC<{}> = () => {
    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const [uploading, setUploading] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [loadingDataSummary, setLoadingDataSummary] = useState<boolean>(false);
    const [showUpload, setShowUpload] = useState(false);
    const [downloadingSample, setDownloadingSample] = useState<string>('');

    const [unitConfiguration, setUnitConfiguration] = useState<IUnitConfiguration>();
    const [fullConfiguration, setFullConfiguration] = useState<IUnitConfiguration>();

    const [dataSummaryList, setDataSummaryList] = useState<IDataSampleSummaryItem[]>();

    const [csvFile, setCsvFile] = useState<File>();

    let theme = useTheme();

    useEffect(() => {
        async function getFullConfig() {
            if (unitConfiguration === undefined) return;
            const configResponse = await apiService.getAsync('settings/unitconfiguration/alfalavalposition', unitConfiguration);
            if (!configResponse.ok) {
                throw new Error('Could not get configuration.');
            }

            const config = await configResponse.json();
            setFullConfiguration(config[0]);
        }

        getFullConfig();
        getDataSamplesAsync(unitConfiguration!);
    }, [unitConfiguration]);

    
    /**
     * Handles the change event for the position selection
     *
     * @param {*} event
     */
    const handleChange = (event: any) => {
        const c = getUnitConfigurationFromConfigurationKey(event);
        setUnitConfiguration(c);
        getDataSamplesAsync(c)
    }

    const inputFile = createRef<HTMLInputElement>();

    
    /**
     * Uploads the csv file
     *
     */
    const uploadCsv = () => {
        inputFile.current?.click();
    }

    
    /**
     * Submits the csv file to the server for processing
     *
     * @return {*} 
     */
    const submitCsv = async () => {
        if (csvFile === undefined || unitConfiguration === undefined) return;

        let formData: FormData = new FormData();
        formData.append("file", csvFile, csvFile.name);

        setUploading(true);
        const response = await apiService.postAsync(formData, 'modeling/sample/csv', fullConfiguration);
        if (!response.ok) {
            setUploading(false);
            let error = '';
            if (response.status === 400) {
                error = await response.text();
            }
            throw new Error(`${response.statusText} ${error}`);
        }

        getDataSamplesAsync(unitConfiguration!);
        setUploading(false);
        setCsvFile(undefined);
    }

    
    /**
     * Handles the file change event for the csv file upload input field 
     *
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
        const uploadedFiles: FileList = e.currentTarget.files!
        const filesToAdd: File[] = []
        Array.from(uploadedFiles).map((file) => {
            filesToAdd.push(file)
        })

        let f = filesToAdd[0];

        setCsvFile(f);
    }

    
    /**
     * Fetches the data samples from the server based on the configuration provided 
     *
     * @param {IUnitConfiguration} configuration
     * @return {*} 
     */
    async function getDataSamplesAsync(configuration: IUnitConfiguration) {
        if (loadingDataSummary || configuration === undefined) return;
        if (configuration.alfaLavalPosition === undefined || configuration.alfaLavalPosition === null) return;
        setLoadingDataSummary(true);
        const response = await apiService.getAsync('modeling/datasamples/summary', configuration);
        if (!response.ok) {
            setLoadingDataSummary(false);
            throw new Error('Could not fetch existing data samples. ' + response.statusText);
        }

        const result: Record<string, IDataSampleSummaryItem> = await response.json();
        let summaries: IDataSampleSummaryItem[] = [];
        Object.keys(result).forEach(key => {
            summaries.push(result[key]);
        });

        setDataSummaryList(summaries);

        const unitConfigResponse = await apiService.getAsync('settings/unitconfigurations', configuration);
        if (!unitConfigResponse.ok) {
            setLoadingDataSummary(false);

            throw new Error('Could not fetch media types. (' + unitConfigResponse.status + ')');
        }

        setLoadingDataSummary(false);
    }

    
    /**
     * Generates a csv file based on the configuration provided 
     *
     * @return {*} 
     */
    async function generateCSV() {
        if (fullConfiguration === undefined) return;

        setDownloading(true);
        const response = await apiService.getAsync('modeling/generatecsv', fullConfiguration);
        if (!response.ok) {
            setDownloading(false);
            throw new Error('Could not fetch csv sample. ' + response.statusText);
        }
        convertStreamToCsv(response.body!, fullConfiguration.processStage + fullConfiguration.alfaLavalPosition + ".csv");
    }

    
    /**
     * Converts a stream to a csv file and downloads it 
     *
     * @param {ReadableStream} stream
     * @param {string} filename
     */
    async function convertStreamToCsv(stream: ReadableStream, filename: string) {
        const reader = stream.getReader();
        let csvData = '';
        while (true) {
            const result = await reader.read();
            if (result.done) {
                break;
            }
            csvData += new TextDecoder().decode(result.value);
        }
        const blob = new Blob([csvData], { type: 'text/csv' });
        saveAs(blob, filename);
        setDownloading(false);
    }

    
    /**
     * Downloads a data sample as a csv file 
     *
     * @param {string} dataSampleId
     */
    async function downloadDataSampleAsCsv(dataSampleId: string) {
        setDownloadingSample(dataSampleId);
        const response = await apiService.getAsync(`modeling/datasamples/download/${dataSampleId}`);
        if (!response.ok) {
            setDownloadingSample('');
            throw new Error('Could not fetch csv sample. ' + response.statusText);
        }
        convertStreamToCsv(response.body!, dataSampleId + ".csv");
        setDownloadingSample('');
    }

    return (
        <Stack>
            <Stack horizontal>
                <Stack.Item grow>
                    <h3 style={{ padding: 10 }}>Modeling</h3>
                </Stack.Item>
                <Stack.Item style={{ marginRight: 20, marginTop: 8 }}>
                    {
                        unitConfiguration?.alfaLavalPosition &&
                        <Stack horizontal>
                            {
                                downloading &&
                                <Stack.Item>
                                    <Spinner style={{ padding: 5 }} />
                                </Stack.Item>
                            }
                            <Stack.Item>
                                <PrimaryButton iconProps={{ iconName: 'Download' }} disabled={downloading} text='Download csv template' onClick={() => { generateCSV() }}></PrimaryButton>
                            </Stack.Item>
                        </Stack>
                    }
                </Stack.Item>
            </Stack>

            <PositionSelection
                onChange={handleChange}
                onIncompleteUnitConfiguration={() => { setDataSummaryList([]) }} />

            {
                uploading &&
                <Spinner label='Uploading data...' size={SpinnerSize.large} />
            }
            {
                loadingDataSummary &&
                <Spinner size={SpinnerSize.large} label='Fetching data summaries...' />
            }
            {
                hasRole(...[Roles[Roles.Modeler]]) && unitConfiguration?.alfaLavalPosition !== '' && showUpload === true ?
                    <Stack horizontalAlign='center'>
                        <IconButton about='Hide' alt='Hide' text='Hide' iconProps={{ iconName: 'Hide' }} onClick={() => setShowUpload(false)} />
                        <input ref={inputFile} type='file' hidden accept='.csv' onChange={handleFileChange} />
                        <TileButton text={`Select data sample CSV file`}
                            iconName='CloudUpload'
                            onClick={() => uploadCsv()} />
                        {
                            csvFile !== undefined &&
                            <Label style={{ marginTop: -15 }}>
                                {csvFile.name}
                            </Label>
                        }
                        <PrimaryButton iconProps={{ iconName: 'CheckMark' }} text='Upload' onClick={() => submitCsv()} disabled={uploading || loadingDataSummary || csvFile === undefined} />
                    </Stack>
                    :
                    <Stack horizontalAlign='center'>
                        {
                            hasRole(...[Roles[Roles.Modeler]]) && unitConfiguration?.alfaLavalPosition &&
                            <ActionButton text='Upload csv' iconProps={{ iconName: 'CloudUpload' }} onClick={() => setShowUpload(true)} />
                        }

                    </Stack>
            }
            {
                dataSummaryList?.length !== 0 ?
                    <Stack>
                        {dataSummaryList &&
                            <Label style={{ padding: '2px 15px' }}>Data samples ({dataSummaryList.length})</Label>
                        }
                        {
                            dataSummaryList &&
                            <DataSampleList
                                dataSamples={dataSummaryList}
                                downloadDataSampleAsCsv={downloadDataSampleAsCsv}
                                downloadingSample={downloadingSample}
                                unitInputConfig={unitConfiguration!}
                                onDelete={() => { getDataSamplesAsync(unitConfiguration!) }}
                            />
                        }
                    </Stack>
                    :
                    !loadingDataSummary &&
                    <Stack horizontalAlign='center'>
                        <Label>No data available for selected position</Label>
                    </Stack>
            }
            {
                hasRole(Roles[Roles.Modeler]) && (unitConfiguration?.alfaLavalPosition === null || unitConfiguration?.alfaLavalPosition === undefined) &&
                <Stack horizontalAlign='center'>
                    <p>Select all parameters before uploading data samples.</p>
                </Stack>
            }
        </Stack >
    )
}

