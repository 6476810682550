import { Stack, getTheme } from "@fluentui/react" // Importing necessary components and functions from Fluent UI
import "./MultiSectionVisualization.scss" // Importing the stylesheet for this component

// Defining the MultiSectionVisualization component with its props
export const MultiSectionVisualization: React.FC<{
    color?: string,
    label?: string,
    backgroundColor?: string,
    leftEnding?: boolean,
    rightEnding?: boolean,
    isActive?: boolean,
    onClick?: any,
    sectionNumber?: number,
    isDrawingMode?: boolean,
    scaling?: number,
}> = (props) => {
    // Getting the current theme
    let theme = getTheme();

    return (
        // Main container stack with click handler and alignment properties
        <Stack onClick={() => props.onClick && props.onClick()} horizontal horizontalAlign="center" verticalAlign="center" style={{ marginTop: 10 }}>
            <Stack.Item align="center" style={{ textAlign: "center" }}>
                {/* Heat exchanger section div with dynamic styles based on props */}
                <div className="heat-exchanger-section" style={{
                    height: 95,
                    width: 200 * (props.scaling ?? 1),
                    paddingTop: 40,
                    paddingBottom: 45,
                    marginLeft: 25 * (props.scaling ?? 1),
                    position: "relative",
                    borderLeft: "10px solid " + (props.leftEnding ? "#113887" : "#a4a4a4"),
                    borderRight: "10px solid " + (props.rightEnding === true ? "#113887" : "#a4a4a4"),
                    borderTopLeftRadius: props.leftEnding ? 5 : 0,
                    borderBottomLeftRadius: props.leftEnding ? 5 : 0,
                    borderTopRightRadius: props.rightEnding ? 5 : 0,
                    borderBottomRightRadius: props.rightEnding ? 5 : 0,
                }}>
                    {/* Label span with dynamic styles based on isActive prop */}
                    <span style={{
                        color: props.isActive ? 'white' : 'black',
                        padding: 8,
                        backgroundColor: props.isActive ? theme.palette.themePrimary : 'white',
                        borderRadius: 8,
                        fontWeight: 600
                    }}>{props.label}</span>
                </div>
            </Stack.Item>
        </Stack>
    )
}