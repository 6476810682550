import { Checkbox, DefaultButton, Label, PrimaryButton, Stack, TextField, useTheme } from '@fluentui/react';
import React, { useState, useEffect, useMemo } from 'react';
import { Constants, PropertyConstants } from '../../../../Models/Constants';
import { IUnitConfiguration } from '../../../../Models/IUnitConfiguration';
import { getLocalStorageItem, getPreferredUnits, setLocalStorageItem } from '../../../../Services/Global';
import { isRelevantInputField } from '../../../../Utils/UnitConfigurationHelper';
import { warmStyling, coldStyling, generalStackStyles } from "../../../../Models/StackStyling";
import { ApiService } from '../../../../Services/ApiService';
import { useMsal } from '@azure/msal-react';

/**
 * Filter component for SME page 
 *
 * @param {*} { unitInputConfiguration, handleFilter }
 * @return {*} 
 */
export const Filter: React.FC<{
    unitInputConfiguration: IUnitConfiguration,
    handleFilter: (query: string) => void
}> = (props) => {

    let theme = useTheme();

    const ctx = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const keyName = props.unitInputConfiguration.industry! + '_' + props.unitInputConfiguration.subIndustry
        + '_' + props.unitInputConfiguration.application + '_' + props.unitInputConfiguration.subApplication + '_' + props.unitInputConfiguration.processStage + '_' + props.unitInputConfiguration.productLine + '_' + props.unitInputConfiguration.alfaLavalPosition;

    let filterObject = JSON.parse(getLocalStorageItem(`${keyName}_filterobject`) ?? '{}');

    const [fullConfiguration, setFullConfiguration] = useState<IUnitConfiguration>(props.unitInputConfiguration);

    const [fromHotMediaFlowRate, setFromHotMediaFlowRate] = useState<string>(filterObject?.fromHotMediaFlowRate || '');
    const [toHotMediaFlowRate, setToHotMediaFlowRate] = useState<string>(filterObject?.toHotMediaFlowRate || '');
    const [fromHotMediaTempIn, setFromHotMediaTempIn] = useState<string>(filterObject?.fromHotMediaTempIn || '');
    const [toHotMediaTempIn, setToHotMediaTempIn] = useState<string>(filterObject?.toHotMediaTempIn || '');
    const [fromHotMediaTempOut, setFromHotMediaTempOut] = useState<string>(filterObject?.fromHotMediaTempOut || '');
    const [toHotMediaTempOut, setToHotMediaTempOut] = useState<string>(filterObject?.toHotMediaTempOut || '');
    const [fromHotMediaPressureDrop, setFromHotMediaPressureDrop] = useState<string>(filterObject?.fromHotMediaPressureDrop || '');
    const [toHotMediaPressureDrop, setToHotMediaPressureDrop] = useState<string>(filterObject?.toHotMediaPressureDrop || '');

    const [fromColdMediaFlowRate, setFromColdMediaFlowRate] = useState<string>(filterObject?.fromColdMediaFlowRate || '');
    const [toColdMediaFlowRate, setToColdMediaFlowRate] = useState<string>(filterObject?.toColdMediaFlowRate || '');
    const [fromColdMediaTempIn, setFromColdMediaTempIn] = useState<string>(filterObject?.fromColdMediaTempIn || '');
    const [toColdMediaTempIn, setToColdMediaTempIn] = useState<string>(filterObject?.toColdMediaTempIn || '');
    const [fromColdMediaTempOut, setFromColdMediaTempOut] = useState<string>(filterObject?.fromColdMediaTempOut || '');
    const [toColdMediaTempOut, setToColdMediaTempOut] = useState<string>(filterObject?.toColdMediaTempOut || '');
    const [fromColdMediaPressureDrop, setFromColdMediaPressureDrop] = useState<string>(filterObject?.fromColdMediaPressureDrop || '');
    const [toColdMediaPressureDrop, setToColdMediaPressureDrop] = useState<string>(filterObject?.toColdMediaPressureDrop || '');

    const [onlyTodo, setOnlyTodo] = useState(filterObject?.onlyTodo || false);

    const preferredTemperatureUnits: string = `°${getPreferredUnits().preferredUnits === 'us' ? 'F' : 'C'}`;
    const preferredFlowUnits: string = getPreferredUnits().preferredUnits === 'us' ? 'lb/h' : 'kg/h';
    const preferredPressureUnits: string = getPreferredUnits().preferredUnits === 'us' ? 'PSI' : 'kPa';

    // Create query string for filter 
    const createQueryString = () => {
        let query = `
        ${isNaN(parseFloat(fromHotMediaFlowRate)) === false ? '&hotMediaFlowrateFrom=' + parseFloat(fromHotMediaFlowRate) : ''}
        ${isNaN(parseFloat(toHotMediaFlowRate)) === false ? '&hotMediaFlowrateTo=' + parseFloat(toHotMediaFlowRate) : ''}
        ${isNaN(parseFloat(fromHotMediaTempIn)) === false ? '&hotMediaTempInFrom=' + parseFloat(fromHotMediaTempIn) : ''}
        ${isNaN(parseFloat(toHotMediaTempIn)) === false ? '&hotMediaTempInTo=' + parseFloat(toHotMediaTempIn) : ''}
        ${isNaN(parseFloat(fromHotMediaTempOut)) === false ? '&hotMediaTempOutFrom=' + parseFloat(fromHotMediaTempOut) : ''}
        ${isNaN(parseFloat(toHotMediaTempOut)) === false ? '&hotMediaTempOutTo=' + parseFloat(toHotMediaTempOut) : ''}
        ${isNaN(parseFloat(fromColdMediaFlowRate)) === false ? '&coldMediaFlowrateFrom=' + parseFloat(fromColdMediaFlowRate) : ''}
        ${isNaN(parseFloat(toColdMediaFlowRate)) === false ? '&coldMediaFlowrateTo=' + parseFloat(toColdMediaFlowRate) : ''}
        ${isNaN(parseFloat(fromColdMediaTempIn)) === false ? '&coldMediaTempInFrom=' + parseFloat(fromColdMediaTempIn) : ''}
        ${isNaN(parseFloat(toColdMediaTempIn)) === false ? '&coldMediaTempInTo=' + parseFloat(toColdMediaTempIn) : ''}
        ${isNaN(parseFloat(fromColdMediaTempOut)) === false ? '&coldMediaTempOutFrom=' + parseFloat(fromColdMediaTempOut) : ''}
        ${isNaN(parseFloat(toColdMediaTempOut)) === false ? '&coldMediaTempOutTo=' + parseFloat(toColdMediaTempOut) : ''}
        ${isNaN(parseFloat(fromColdMediaPressureDrop)) === false ? '&coldMediaPressureDropFrom=' + parseFloat(fromColdMediaPressureDrop) : ''}
        ${isNaN(parseFloat(toColdMediaPressureDrop)) === false ? '&coldMediaPressureDropTo=' + parseFloat(toColdMediaPressureDrop) : ''}
        ${isNaN(parseFloat(fromHotMediaPressureDrop)) === false ? '&hotMediaPressureDropFrom=' + parseFloat(fromHotMediaPressureDrop) : ''}
        ${isNaN(parseFloat(toHotMediaPressureDrop)) === false ? '&hotMediaPressureDropTo=' + parseFloat(toHotMediaPressureDrop) : ''}
        ${onlyTodo ? '&onlyTodo=true' : ''}
        `;

        query = query.replace(/\r?\n|\r/g, '').replace(/\s/g, '');
        return query;
    }

    
    /**
     * Handles filter 
     *
     * @param {boolean} [blank]
     */
    const handleFilter = (blank?: boolean) => {
        if (blank) {

            setFromColdMediaFlowRate('');
            setToColdMediaFlowRate('');
            setFromColdMediaTempIn('');
            setToColdMediaTempIn('');
            setFromColdMediaTempOut('');
            setToColdMediaTempOut('');
            setFromColdMediaPressureDrop('');
            setToColdMediaPressureDrop('');

            setFromHotMediaFlowRate('');
            setToHotMediaFlowRate('');
            setFromHotMediaTempIn('');
            setToHotMediaTempIn('');
            setFromHotMediaTempOut('');
            setToHotMediaTempOut('');
            setFromHotMediaPressureDrop('');
            setToHotMediaPressureDrop('');

            setOnlyTodo(false);

            props.handleFilter('');
            setLocalStorageItem(`${keyName}_currentFilter`, '');
            setLocalStorageItem(`${keyName}_filterobject`, JSON.stringify({
                fromColdMediaFlowRate: '',
                toColdMediaFlowRate: '',
                fromColdMediaTempIn: '',
                toColdMediaTempIn: '',
                fromColdMediaTempOut: '',
                toColdMediaTempOut: '',
                fromColdMediaPressureDrop: '',
                toColdMediaPressureDrop: '',
                fromHotMediaFlowRate: '',
                toHotMediaFlowRate: '',
                fromHotMediaTempIn: '',
                toHotMediaTempIn: '',
                fromHotMediaTempOut: '',
                toHotMediaTempOut: '',
                fromHotMediaPressureDrop: '',
                toHotMediaPressureDrop: '',
                onlyTodo: false
            }))
        }
        else {
            props.handleFilter(createQueryString().trim());
            setLocalStorageItem(`${keyName}_currentFilter`, createQueryString().trim());
            setLocalStorageItem(`${keyName}_filterobject`, JSON.stringify({
                fromColdMediaFlowRate: fromColdMediaFlowRate,
                toColdMediaFlowRate: toColdMediaFlowRate,
                fromColdMediaTempIn: fromColdMediaTempIn,
                toColdMediaTempIn: toColdMediaTempIn,
                fromColdMediaTempOut: fromColdMediaTempOut,
                toColdMediaTempOut: toColdMediaTempOut,
                fromColdMediaPressureDrop: fromColdMediaPressureDrop,
                toColdMediaPressureDrop: toColdMediaPressureDrop,
                fromHotMediaFlowRate: fromHotMediaFlowRate,
                toHotMediaFlowRate: toHotMediaFlowRate,
                fromHotMediaTempIn: fromHotMediaTempIn,
                toHotMediaTempIn: toHotMediaTempIn,
                fromHotMediaTempOut: fromHotMediaTempOut,
                toHotMediaTempOut: toHotMediaTempOut,
                fromHotMediaPressureDrop: fromHotMediaPressureDrop,
                toHotMediaPressureDrop: toHotMediaPressureDrop,
                onlyTodo: onlyTodo
            }));
        }
    }

    useEffect(() => {
        async function getFullConfig() {
            if (props.unitInputConfiguration) {
                const configResponse = await apiService.getAsync('settings/unitconfiguration', props.unitInputConfiguration);
                if (!configResponse.ok) {
                    throw new Error('Could not get configuration for filter.');
                }

                const config = await configResponse.json();
                setFullConfiguration(config);
            }
        }

        getFullConfig();
    }, [])

    return (

        <Stack horizontal>
            {
                fullConfiguration &&
                <>

                    {/* Hot */}
                    <Stack horizontal styles={warmStyling(theme)}>
                        {
                            isRelevantInputField(Constants.HotMediaFlowrate, fullConfiguration) &&
                            <Stack styles={generalStackStyles(theme)}>

                                <Label>{fullConfiguration.hotMediaType} flow rate</Label>
                                <Stack.Item>
                                    <label>From:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={fromHotMediaFlowRate} suffix={preferredFlowUnits} onChange={(e) => {
                                        setFromHotMediaFlowRate(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                                <Stack.Item style={{ marginTop: 10 }}>
                                    <label>To:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={toHotMediaFlowRate} suffix={preferredFlowUnits} onChange={(e) => {
                                        setToHotMediaFlowRate(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                            </Stack>
                        }

                        {
                            isRelevantInputField(Constants.HotMediaTempIn, fullConfiguration) &&
                            <Stack styles={generalStackStyles(theme)}>

                                <Label>{fullConfiguration.hotMediaType} temperature inlet</Label>

                                <Stack.Item>
                                    <label>From:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={fromHotMediaTempIn} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setFromHotMediaTempIn(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                                <Stack.Item style={{ marginTop: 10 }}>
                                    <label>To:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={toHotMediaTempIn} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setToHotMediaTempIn(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                            </Stack>
                        }

                        {
                            isRelevantInputField(Constants.HotMediaTempOut, fullConfiguration) &&
                            <Stack styles={generalStackStyles(theme)}>

                                <Label>{fullConfiguration.hotMediaType} temperature outlet</Label>

                                <Stack.Item>
                                    <label>From:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={fromHotMediaTempOut} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setFromHotMediaTempOut(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                                <Stack.Item style={{ marginTop: 10 }}>
                                    <label>To:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={toHotMediaTempOut} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setToHotMediaTempOut(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                            </Stack>
                        }

                        {
                            isRelevantInputField(Constants.HotMediaPressureDrop, fullConfiguration) &&
                            <Stack styles={generalStackStyles(theme)}>
                                <Label>{fullConfiguration.hotMediaType} pressure drop</Label>

                                <Stack.Item>
                                    <label>From:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={fromHotMediaPressureDrop} suffix={preferredPressureUnits} onChange={(e) => {
                                        setFromHotMediaPressureDrop(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                                <Stack.Item style={{ marginTop: 10 }}>
                                    <label>To:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={toHotMediaPressureDrop} suffix={preferredPressureUnits} onChange={(e) => {
                                        setToHotMediaPressureDrop(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                            </Stack>
                        }
                    </Stack>

                    {/* Cold */}
                    <Stack horizontal styles={coldStyling(theme)}>
                        {
                            isRelevantInputField(Constants.ColdMediaFlowrate, fullConfiguration) &&
                            <Stack styles={generalStackStyles(theme)}>
                                <Label>{fullConfiguration.coldMediaType} mass flow rate</Label>

                                <Stack.Item>
                                    <label>From:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={fromColdMediaFlowRate} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setFromColdMediaFlowRate(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                                <Stack.Item style={{ marginTop: 10 }}>
                                    <label>To:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={toColdMediaFlowRate} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setToColdMediaFlowRate(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                            </Stack>
                        }

                        {
                            isRelevantInputField(Constants.ColdMediaTempIn, fullConfiguration) &&

                            <Stack styles={generalStackStyles(theme)}>

                                <Label>{fullConfiguration.coldMediaType} temperature inlet</Label>

                                <Stack.Item>
                                    <label>From:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={fromColdMediaTempIn} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setFromColdMediaTempIn(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                                <Stack.Item style={{ marginTop: 10 }}>
                                    <label>To:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField style={{ height: '30px !important' }} type='number' value={toColdMediaTempIn} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setToColdMediaTempIn(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                            </Stack>

                        }

                        {
                            isRelevantInputField(Constants.ColdMediaTempOut, fullConfiguration) &&
                            <Stack styles={generalStackStyles(theme)}>
                                <Label>{fullConfiguration.coldMediaType} temperature outlet</Label>

                                <Stack.Item>
                                    <label>From:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={fromColdMediaTempOut} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setFromColdMediaTempOut(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                                <Stack.Item style={{ marginTop: 10 }}>
                                    <label>To:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={toColdMediaTempOut} suffix={preferredTemperatureUnits} onChange={(e) => {
                                        setToColdMediaTempOut(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                            </Stack>
                        }

                        {
                            isRelevantInputField(Constants.ColdMediaPressureDrop, fullConfiguration) &&
                            <Stack styles={generalStackStyles(theme)}>
                                <Label>{fullConfiguration.coldMediaType} pressure drop</Label>
                                <Stack.Item>
                                    <label>From:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={fromColdMediaPressureDrop} suffix={preferredPressureUnits} onChange={(e) => {
                                        setFromColdMediaPressureDrop(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                                <Stack.Item style={{ marginTop: 10 }}>
                                    <label>To:</label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField type='number' value={toColdMediaPressureDrop} suffix={preferredPressureUnits} onChange={(e) => {
                                        setToColdMediaPressureDrop(e.currentTarget.value);
                                    }} />
                                </Stack.Item>
                            </Stack>
                        }
                    </Stack>

                    <Stack>
                        <Checkbox styles={{ root: { marginLeft: 20, marginTop: 5 } }} label='Todo only' checked={onlyTodo} onChange={(e) => {
                            setOnlyTodo(!onlyTodo);
                        }} />
                        <PrimaryButton
                            style={{ marginLeft: 20, marginTop: 20 }}
                            onClick={() => handleFilter()}
                        >
                            Apply filter
                        </PrimaryButton>
                        <DefaultButton style={{ marginLeft: 20, marginTop: 20 }}
                            onClick={() => handleFilter(true)}>
                            Reset filters
                        </DefaultButton>
                    </Stack>
                </>
            }
        </Stack>
    );
}