import { Stack } from '@fluentui/react/lib/Stack';
import { To, useNavigate } from 'react-router-dom';
import { DefaultButton, Icon, PrimaryButton } from '@fluentui/react';
import { Badge } from '@fluentui/react-components';


export const FilterToggle: React.FC<{backPath: To, showFilters: boolean, isFiltering: boolean, isLoading: boolean, toggleShowFilters? : any, resultCount: number}> = (props) => {
  let navigate = useNavigate();
  let showFilters = props.showFilters;
  const resultCount = props.resultCount || 0;

  let resultsText = `${props.resultCount} filtered results`;

  if (resultCount === 0) {
    resultsText = '0 filtered results';
  } else if (resultCount === 1) {
    resultsText = '1 filtered result';
  }

  return (
    props.isLoading ? null :
    <Stack horizontal horizontalAlign='center'>
        <Stack.Item align='start'>
                <DefaultButton iconProps={{ iconName: 'back' }} onClick={() => navigate(props.backPath)}>Back</DefaultButton>
                    {
                        props.isFiltering &&

                        <>
                        <Badge appearance='filled' color='warning' style={{ padding: 5, margin: '5px 10px' }}>
                            <Icon iconName='Warning' style={{ marginRight: 5 }} />
                                <span>Filters are active.</span>
                        </Badge>
                        <Badge appearance='filled' color={resultCount > 0 ? 'success' : 'warning'} style={{ padding: 5, margin: '5px 0px' }} >
                            <Icon iconName='Filter' style={{ marginRight: 5 }} />
                                <span>{resultsText}</span>
                        </Badge>
                        </>

                    }
                </Stack.Item>

                <Stack.Item align='center' grow>
                    <br></br>
                </Stack.Item>

                <Stack.Item align='end'>
                    <PrimaryButton iconProps={{ iconName: 'Filter' }} disabled={props.isLoading} onClick={() => props.toggleShowFilters()}>{showFilters! ? 'Hide filters' : 'Show filters'}</PrimaryButton>
                </Stack.Item>
            </Stack>
  );
};
export default FilterToggle