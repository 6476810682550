import { IMsalContext } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from "../AuthConfig";
import jwt_decode from "jwt-decode";
import { setRoles, setUserFirstname, setUserLastname } from "./Global";

/**
 * Authentication service for handling authentication requests 
 *
 * @export
 * @class AuthenticationService
 */
export class AuthenticationService {

    private context: IMsalContext;

    constructor(ctx: IMsalContext) {
        this.context = ctx;
    }

    
    /**
     * Get access token from the authentication context 
     *
     * @return {*}  {Promise<string>}
     * @memberof AuthenticationService
     */
    public getAccessToken(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            const { instance, accounts } = this.context;
            const account = accounts[0]
            const scopes = loginRequest.scopes
            let response;
            try {
                response = await instance.acquireTokenSilent({
                    account: account!,
                    scopes: scopes
                });

                if (response.accessToken) {
                    var token: any = jwt_decode(response.accessToken);
                    setRoles(token.roles)
                    setUserFirstname(token.given_name ?? 'Profile');
                    setUserLastname(token.family_name ?? '');
                    resolve(response.accessToken);
                } else {
                    reject(Error('Failed to acquire an access token'));
                }
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        scopes: scopes
                    });
                }
                else {
                    reject(error)
                }
            }
        });
    }

    
    /**
     * Login popup for authentication
     *
     * @memberof AuthenticationService
     */
    public loginPopup() {
        const { instance } = this.context;
        console.log(InteractionStatus)
        const request = {
            scopes: loginRequest.scopes
        };
        instance.loginPopup(request).catch((error) => {
            // swallow?
            console.log(error);
        });
    }

    
    /**
     * Login redirect for authentication
     *
     * @memberof AuthenticationService
     */
    public loginRedirect() {
        const { instance } = this.context;
        console.log(InteractionStatus)
        const request = {
            scopes: loginRequest.scopes
        };
        instance.loginRedirect(request).catch((error) => {
            // swallow?
            console.log(error);
        });
    }


}