import { Stack, Label, FontSizes, getTheme } from "@fluentui/react";
import { Badge, Text } from "@fluentui/react-components";
import { generalStackStyles, hoverableStackStyles, informationLabelStyle } from "../../../Models/StackStyling";
import { IMultiSectionUnitConfiguration } from "../../../Models/IUnitConfiguration";

/**
 * MultiSectionTiles component
 *
 * @param {{ sections: IMultiSectionUnitConfiguration[] }} props
 * @return {*} 
 */
export const MultiSectionTiles: React.FC<{ sections: IMultiSectionUnitConfiguration[] }> = (props) => {

    // Get the current theme
    let theme = getTheme();

    // Get the first section from the props
    const fs = props.sections[0];

    // Sort the sections in descending order based on sectionNumber
    const orderedSections = props.sections.sort((a, b) => { return b.sectionNumber! - a.sectionNumber! });

    return (
        <Stack styles={generalStackStyles(theme)} style={{ margin: 20 }}>
            <Stack style={{ padding: 8, marginBottom: 10 }}>
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 20 }} >
                    <Stack.Item>
                        <Text>Industry</Text>
                        <Label styles={informationLabelStyle(theme)}>{fs.industry}</Label>
                    </Stack.Item>
                    <Stack.Item>
                        <Text>SubIndustry</Text>
                        <Label styles={informationLabelStyle(theme)}>{fs.subIndustry}</Label>
                    </Stack.Item>
                    <Stack.Item>
                        <Text>Application</Text>
                        <Label styles={informationLabelStyle(theme)}>{fs.application}</Label>
                    </Stack.Item>
                    <Stack.Item>
                        <Text>SubApplication</Text>
                        <Label styles={informationLabelStyle(theme)}>{fs.subApplication}</Label>
                    </Stack.Item>
                </Stack>
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 20 }} style={{ marginTop: 5 }}>
                    <Stack.Item>
                        <Text>ProcessStage</Text>
                        <Label styles={informationLabelStyle(theme)}>{fs.processStage}</Label>
                    </Stack.Item>
                    <Stack.Item>
                        <Text>ProductLine</Text>
                        <Label styles={informationLabelStyle(theme)}>{fs.productLine}</Label>
                    </Stack.Item>
                    <Stack.Item>
                        <Text>AlfaLavalPosition</Text>
                        <Label styles={informationLabelStyle(theme)}>{fs.alfaLavalPosition}</Label>
                    </Stack.Item>
                    <Stack.Item>
                        <Text>FlowType</Text>
                        <Label styles={informationLabelStyle(theme)}>{fs.flowType}</Label>
                    </Stack.Item>
                </Stack>

                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 20 }} style={{ marginTop: 10 }}>
                    <Badge appearance="tint" color="success">Operation target: {fs.unitOperationTargetName} {fs.unitOperationTargetValueUnit}</Badge>
                </Stack>
            </Stack>
            <Stack horizontal wrap horizontalAlign="center">
                {
                    orderedSections.map((section) => {

                        return (
                            <Stack key={`section-${section.sectionNumber}`}>
                                <Stack styles={hoverableStackStyles(theme)} style={{ borderTop: `solid 5px ${theme.palette.themePrimary}`, margin: 5, padding: 10, minWidth: 370 }}>
                                    <Stack horizontal tokens={{ childrenGap: 5 }} style={{ borderBottom: `solid 1px ${theme.palette.themePrimary}` }}>
                                        <Stack.Item grow>
                                            <Label>Section {section.sectionNumber!}</Label>
                                        </Stack.Item>
                                        <Stack.Item style={{ padding: 5 }}>
                                            <Badge style={{ background: theme.palette.redDark, color: 'white' }}>{section.hotMediaType}</Badge>
                                        </Stack.Item>
                                        <Stack.Item style={{ padding: 5 }}>
                                            <Badge style={{ background: theme.palette.themePrimary, color: 'white' }}>{section.coldMediaType}</Badge>
                                        </Stack.Item>
                                    </Stack>
                                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                                        <Stack.Item style={{ fontSize: FontSizes.small, padding: 0 }}>
                                            <Label >Inputs</Label>
                                            {
                                                section.inputs?.map(i => {
                                                    return (
                                                        <p>{i}</p>
                                                    )
                                                })
                                            }
                                        </Stack.Item>
                                        <Stack.Item style={{ fontSize: FontSizes.small, padding: 0 }}>
                                            <Label >Outputs</Label>
                                            {
                                                section.outputs?.map(o => {
                                                    return (
                                                        <p>{o}</p>
                                                    )
                                                })
                                            }
                                        </Stack.Item>
                                        <Stack.Item style={{ fontSize: FontSizes.small, padding: 0 }}>
                                            <Label>Not used</Label>
                                            {
                                                section.nonUsedProperties?.map(nu => {
                                                    return (
                                                        <p>{nu}</p>
                                                    )
                                                })
                                            }
                                        </Stack.Item>
                                    </Stack>
                                </Stack>
                                <Stack.Item style={{ marginLeft: 10 }}>
                                    <Badge appearance="ghost">Calculation type: {section.calculationType}</Badge>
                                </Stack.Item>
                            </Stack>
                        )
                    })
                }
            </Stack>
        </Stack>
    )
};